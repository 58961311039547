.items {
  margin-bottom: 30px;
  max-width: 1400px;

  .component {
    &--header-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    &--header-row {
      display: flex;
      height: 40px;
      align-items: center;
      width: 100%;
      flex: 0 0 100%;
    }

    &--header {

      &-section {
        flex: 1;
        max-width: 915px;
        .mr-16p {
          margin-right: 16px;
        }
      }
    }
  }

  .table {
    &--row {
      font-size: 12px;
    }
  }

  &--content {

    .table {
      width: 100%;

      @media screen and (max-width: 1476px) {
        max-width: 1145px;
      }
    }
    .show-on-home-page {
      margin-top: 10px;
      width: auto;

      .ant-switch-small {
        background-color: #FF0000;
      }

      .ant-switch-checked {
        background-color: #1DD303;
      }

      &__label {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin-right: 7px;
      }
    }

    .actions-column {
      .item-history-icon {
        cursor: pointer;
        font-size: 17px;
        margin-left: 7px;
      }
    }

    .dealer-column {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &__right {
        &.editable {
          width: 100%;
          text-align: center;

          svg {
            &:first-child {
              margin-left: 0;
            }
          }
        }

        svg {
          cursor: pointer;

          &:last-child {
            margin: 0 15px 0 0;
          }
        }
      }

      b {
        color: #000000;
        margin-bottom: 2px;
      }
    }

    .status-column {
      position: relative;

      .anticon {
        position: absolute;
        left: 5px;

        &:hover {
          cursor: pointer;
        }
      }

      & > i {
        transition: transform .4s ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .created-column {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    .item-column {
      flex-wrap: nowrap;
      align-items: start;

      &--sold-label {
        margin-top: 8px;
      }

      &--status-panel {
        margin-top: 10px;

        .red-color {
          color: #FF0000;
        }
      }

      &--approved-by {
        margin: 5px 0;

        &--title {
          margin-right: 5px;
          padding: 0 4px;
          border-radius: 4px;
          background: #C2C2C2;
          color: #ffffff;
          font-size: 12px;

          span {
            background: #ffffff;
            color: #000000;
          }
        }
      }

      .img {
        width: 108px;
        height: 108px;
        margin-right: 8px;
        border-radius: 4px;
        object-fit: cover;
      }

      .status-panel {
        display: flex;
      }

      & > div {
        color: #000000;
      }
    }
    &__row {
      margin-bottom: 4px;
      .table--row {
        margin-bottom: 0;
      }
    }

    .price-column {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    .ref-column {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--list__content {
    width: 100%;
    overflow: hidden;
    background-color: #FFFFFF;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    max-height: 0;
    border-top: 0;
    padding: 0 16px;

    .images-list {
      width: 100%;
      max-height: 130px;
      overflow: auto;
    }

    .ant-col {
      margin: 10px 0;
    }

    i {
      margin: 5px;
    }

    &.open {
      border-top: 1px solid #E6E6E6;
      max-height: initial;
      transition: max-height 1s ease-in-out;
    }

    label {
      margin-bottom: 4px;
      font-family: "IBM Plex Sans Condensed", serif;
      display: block;
    }

    .item {
      margin-bottom: 16px;

      input {
        width: 100%;
      }
    }

    .disabled-input {
      padding: 6.5px 16.5px;
      background-color: $primary-content-bg;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      white-space: pre-line;
      min-height: 33px;
      line-height: 18px;
      color: #000000;
      cursor: text;
      overflow: hidden;
      text-overflow: ellipsis;

      &.no-assigned {
        cursor: default;
        text-align: center;
        span {
          opacity: .5;
        }
      }

      &.many {
        cursor: default;
        position: relative;
        padding-right: 35px;

        .close-icon {
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    input {
      &:focus {
        border-color: #000000;
        outline: none;
      }
      height: 33px;
      width: 150px;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      padding: 0 16.5px;
      color: #000000;
    }

    textarea {
      &:focus {
        border-color: #000000;
        outline: none;
      }

      width: 100%;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      padding: 6.5px 16.5px;
      color: #000000;
      min-height: 33px;
      height: 230px;
      display: block;
    }

    .submit-button {
      margin-top: 5px;
    }

    .ant-collapse {
      &-borderless > .ant-collapse-item {
        border-bottom: none;
      }

      &-header {
        transition: color 1s;
        display: flex;
        align-items: center;
        &[aria-expanded=true] {
          color: #717171 !important;
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        }
      }
    }

    &__left {
      margin-top: 24px;
      margin-bottom: 27px;

      .thumbnail {

        img,
        &.empty-image {
          width: 225px;
          height: 188px;
          object-fit: cover;
        }
        margin-right: 24px;
        display: inline-block;
      }

      .images {
        &-list {
          &--item {
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 8px;
          }

          img {
            width: 55px;
            height: 60px;
            object-fit: cover;
          }
        }

        .zoom-in {
          position: relative;
          svg {
            position: absolute;
            pointer-events: none;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:hover {
            svg {
              z-index: 1;
            }
            img {
              background-color: #717171;
              opacity: .6;
              cursor: pointer;
            }
          }
        }
      }
    }
    &__right {
      margin-top: 24px;
      margin-bottom: 27px;

      .additional-categories-carousel {
        .ant-collapse-content-box {
          padding: 0;
        }
      }

      .additional-carousel {
        &--categories {
          margin: 10px 0;
          display: flex;
          justify-content: space-between;

          &--select {
            width: 100%;
          }
        }

        .select-wrapper {
          width: 100%;

          &.two-in-row {
            width: 45%;
          }

          .ant-select {
            .ant-select-selector {
              min-height: 37px;
            }
          }

          .ant-select-selection-item {
            display: flex;
            align-items: center;
          }
        }

        &--label {
          font-size: 10px;
          font-weight: 700;
          color: #717171;
          font-family: "IBMPlexSansCondensed-Regular", serif;
        }

        &--select {
          width: 100%;
          min-height: 40px;

          .ant-select-selection {
            background-color: #f7f7f7;
          }
        }

        &--switch {
          margin-top: 15px;

          .ant-switch {
            background-color: #FF0000;
          }

          .ant-switch-checked {
            background-color: #1DD303;
          }
        }
      }

      .add-category {
        color: #fff;
        min-width: 16px;
        height: 16px;
        font-size: 18px;
        background-color: $primary-bg-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        line-height: 1rem;
        margin-left: 8px;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      }

      .categories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .disabled-input {
          margin: 0 10px 5px 0;
        }
      }
    }

    .has-error {
      margin-top: 5px;
    }
  }

  .line-height-1 {
    line-height: 1;
  }

  &--measurements-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 0 0 10px;

    &--container {
      width: 100%;
      display: flex;

      &--custom {
        width: 100%;

        .ant-input {
          width: 100%;
          white-space: pre-wrap;
        }
      }
    }

    &--height-field,
    &--width-field,
    &--depth-field,
    &--length-field,
    &--diameter-field,
    &--weight-field {
      display: flex;
      align-items: center;
      width: 45%;
      position: relative;

      .labeled-input {
        width: auto;
      }

      .ant-dropdown-trigger {
        margin: 20px 0 0 8px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &--save-button {
      margin: 8px 0 0 0;
    }

    .ant-btn {
      margin: 0;
    }
  }

  &--dealer-column--dealer-name {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 0 10px 10px 0;

    &--title {
      margin: 0 0 4px 0;
    }

    &--id {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  #items--mfg-field--date-picker {
    height: 18px;
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    margin: 4px 0 0 0;
  }

  &--mfg-created-date-field {
    margin: 0 0 10px 0;
  }

  .DateInput_input {
    text-align: center;
  }

  &--dealer-filter {
    margin: 8px 0;
    width: 100%;

    .ant-select {
      margin: 0 !important;
      width: 100% !important;
    }
  }

  &--search {
    display: flex;
    gap: 16px;
    margin: 8px 0;
  }
}

.ant-collapse-content-inactive{
  display: none;
}

.ant-collapse-content-active{
  display: block;
}

.ant-collapse-header {
  padding: 6px 8px !important;
}
