.lant-table {
  &--actions-column {
    display: flex;
    align-items: center;
    gap: 16px;

    .anticon,
    .lant-icon {
      cursor: pointer;
    }
  }

  &--tag {
    max-height: 16px;
    height: 16px;
    margin: 0 2px 2px 0;
    padding: 0 4px;
    color: #fff;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 3px;
    background-color: #c2c2c2;

    &.thin {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    &.bold {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    &.confirmed,
    &.active {
      background-color: #4f9a00;
    }

    &.available {
      background-color: #1ab394;
    }

    &.category {
      background-color: #104F56;
    }

    &.reserved {
      background-color: #2196f3;
    }

    &.sold {
      background-color: #eb9838;
    }

    &.pending {
      background-color: #f89407;
    }

    &.unconfirmed {
      background-color: #f00;
    }

    &.disabled {
      cursor: default;
    }
  }
}
