.status-card {
  &--title {
    color: #000000;
    font-family: "IBMPlexSans-Bold", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }

  &--content {
    min-width: 260px;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin: 8px 0 0 0;
    padding: 16px;
  }

  &--field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 16px;
    min-width: 130px;
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 16px;
    margin: 0 44px 8px 0;
  }

  &--field-label {
    min-width: 130px;
    margin: 0 44px 0 0;
  }

  b,
  strong,
  &--field-value__bold {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
  }

  &--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
  }
}
