.dealer-charges {
  .table {
    &--row {
      font-size: 12px;
    }
    &--column:not(.table--header-cell) {
      color: #000000;
      font-family: IBMPlexSansCondensed-Medium, sans-serif;

      &.number-invoices-column {
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        color: #717171;
      }

      &.actions-column {
        justify-content: flex-end;
      }
    }
    &--header-cell {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    .groups--table-icon {
      margin-right: 8px;
    }
  }
}
