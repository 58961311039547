.tab-navigation {
  &--item {
    display: flex;
  }

  &--content {
    padding-top: 24px;
  }

  &--icon {
    margin-right: 8px;
  }
}
