.header {
  display: flex;
  background-color: $primary-content-bg;
  padding: 15px 80px 30px 50px;
  justify-content: space-between;

  &--part {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--title {
    font-size: 18px;
    margin: 0;
  }

  &--user-info {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  }

  &--user-text {
    color: $secondary-fg-color;
    font-family: IBMPlexSansCondensed-Regular, sans-serif;

    &__bold {
      color: $primary-fg-color
    }
  }

  &--logout {
    cursor: pointer;
    margin-left: 15px;
  }

  #global-search {
    width: 280px;
    margin: 0 10px;

    @media screen and (min-width: 1400px) {
      width: 350px;
    }

    @media screen and (min-width: 1480px) {
      width: 450px;
    }

    @media screen and (min-width: 1600px) {
      width: 540px;
    }
  }

  .search-results {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--wrapper {
      width: 540px;
      min-height: 50px;
      background: white;
      border-radius: 3px;
      position: absolute;
      z-index: 1000;
      top: 33px;

      @media screen and (max-width: 1120px) {
        width: 440px;
      }
      @media screen and (max-width: 1000px) {
        width: 350px;
      }
      @media screen and (max-width: 920px) {
        width: 250px;
      }
    }

    &--collection {
      padding: 5px 20px;
    }

    &--name-of-collection {
      font-size: 16px;
      text-transform: uppercase;
      color: #5E77FF;
      margin: 0 0 5px 0;
    }

    &--title-of-element {
      font-size: 12px;
      color: #BABDD0;
    }

    &--wrapper-hidden {
      display: none;
    }

    &--empty-search {
      font-size: 14px;
      color: #BABDD0;
      padding: 15px;
    }
  }
}
