.pages {

  .component--header {
    &-sort-button {
      margin: 0 8px;
    }

    &-sort-button div:first-child {
      width: 128px;
    }
  }
  .component--header-section {
    .button {
      padding: 0 21px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      &--icon {
        margin-right: 10px;
      }
    }
  }
  &--content {
    position: relative;
    .title-column {
      color: $primary-fg-color;
      font-size: 12px;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }
    .url-column {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
    }
  }

  &--table {
    width: 100%;

    &__shrink {
      flex-grow: 0;
      flex-basis: calc(66.6% - 15px);
    }
  }
  .table--row {
    cursor: pointer;
  }

}

.edit-page {
  box-sizing: border-box;
  background-color: $secondary-content-bg;
  padding: 15px;
  transition: width .3s, background-color .3s, padding .3s;

  &__fullscreen {
    background-color: $primary-content-bg;
  }
  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }
    &.image-holder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        margin: 0 8px;
        cursor: pointer;
      }
    }
    #full-screen-exit-icon {
      height: 14px;
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 25px;
  }
  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    max-width: 350px;

    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    input {
      max-width: 350px;
    }
  }

  &--button-holder {
    display: flex;
    margin-top: 20px;

    .del-btn-holder {
      margin-left: 15px;
      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }
      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
      }
    }
  }

  &--page-content-input {
    max-width: 100% !important;
  }
}

.add-new-page {
  box-sizing: border-box;
  width: calc(100% - 130px);
  margin: 0 0 0 50px;

  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }
    &.image-holder {
      img {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }
  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    max-width: 350px;

    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    input {
      max-width: 350px;
    }
  }

  .add-new-page--page-content-input {
    min-height: 278px;
    .ql-container {
      min-height: 237px;
    }
  }

  &--button-holder {
    display: flex;
    margin-top: 20px;

    .del-btn-holder {
      margin-left: 15px;
      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }
      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
      }
    }
  }

  &--page-content-input {
    max-width: 100% !important;
  }

  &--url-input-holder {
    .labeled-input {
      max-width: 600px;

      &--label {
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      }

      input {
        max-width: 600px;
      }
    }

    .ant-input {
      padding-left: 5px;
    }

    .ant-input-group-addon {
      padding: 2px 5px 0 5px;
    }
  }
}
