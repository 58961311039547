.table {
  display: flex;
  flex-direction: column;
  flex: 1;

  .destination-column {
    .destination-column--content {
      min-height: 22px;
      width: 112px;
      border-radius: 4px;
      background-color: #004f57;
      font-size: 12px;
      font-weight: 700;
      color: #FFFFFF;
      text-align: center;
      line-height: 21px;
    }
  }

  &--header {
    display: flex;
    height: 40px;
    align-items: center;

    .table--column {
      background-color: initial;
    }
  }

  &--row {
    display: flex;
    margin-bottom: 4px;
    align-items: stretch;
    min-height: 32px;

    .ant-checkbox-wrapper {
      justify-content: center;
    }

    &__highlighted .table--column {
      background-color: #F1F1F4;
    }
  }

  &--footer {
    display: flex;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    height: 32px;
  }

  &--column {
    flex-shrink: 0;
    flex-wrap: wrap;
    box-sizing: border-box;
    background-color: $secondary-content-bg;
    padding: 4px 4px 4px 8px;
    word-break: break-word;

    &.ant-checkbox-wrapper {
      background-color: transparent;
    }
  }

  &--header-cell {
    height: 40px;
    color: $secondary-fg-color;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &--header-label {
    margin-right: 5px;
  }

  &--footer-show,
  &--footer-total {
    display: flex;
    width: 15%;
    height: 100%;
    flex-shrink: 0;
    align-items: center;
    color: $secondary-fg-color
  }

  &--footer-show-select {
    width: 100%;
  }

  &--footer-total {
    justify-content: flex-end;
  }

  &--footer-pages {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
  }

  &--footer-pages-control {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-bg-color;
    color: #ffffff;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    margin: 0 12px;
    cursor: pointer;
    user-select: none;

    &__hidden {
      opacity: 0;
      cursor: initial;
    }
  }

  &--footer-pages-button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-content-bg;
    color: $primary-bg-color;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    margin: 0 2px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.5s, color 0.5s, border 0.5s;

    &__selected {
      background-color: $primary-bg-color;
      color: $secondary-content-bg;
      border: 1px solid $primary-bg-color;
    }

    &__divider {
      background-color: initial;
      border: none;
      width: initial;
      color: $secondary-fg-color;
      margin: 0 5px;
    }

    &__three-digits {
      width: auto;
      padding: 0 8px;
    }

    &:hover:not(&__divider, &__selected) {
      color: $primary-bg-color;
      border: 1px solid $primary-bg-color;
    }
  }

  &--footer-show-input {
    width: 80px;
    height: 32px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: $secondary-content-bg;
    font-size: 14px;
    margin-left: 8px;
    padding: 0 12px;
    box-sizing: border-box;
  }
}

.delete-icon-root {
  border: 0 !important;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 30px;
    height: 30px;
    padding: 6px;
  }
}
