.lant-form {
  background-color: transparent;

  &--content {
    max-width: 1020px;
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--section {
    padding-top: 16px;
  }

  &--title {
    margin: 0;
    padding: 0 0 20px;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    color: #000000;
    font-size: 20px;
  }

  &--item {
    .ant-form-item-label {
      display: flex;
      padding-bottom: 4px;

      label {
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        color: #717171;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .ant-form-item-required:before {
      order: 2;
      margin-left: 2px;
    }

    .ant-form-item-explain-error {
      font-size: 12px;
    }
  }

  &--upload {
    &.multiple-images-uploader .ant-upload-picture-card-wrapper {
      justify-content: flex-start;
    }

    .ant-upload-list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &--footer {
    display: flex;
    gap: 16px;
    padding: 20px 0;

    &__btn {
      text-transform: uppercase;
    }
  }

  .ant-select-in-form-item {
    &:not(.ant-select-disabled):hover .ant-select-selector,
    &:not(.ant-select-disabled).ant-select.ant-select-focused .ant-select-selector {
      border-color: initial;
      box-shadow: none;
      outline: none;
    }
  }
}

.lant-checkbox-vertical {
  flex-direction: column-reverse;
  align-items: flex-start;

  &:after {
    height: 0;
  }

  .ant-checkbox {
    margin: 8px 0;

    & + span {
      padding: 0 0 4px;
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
