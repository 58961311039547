.dashboard {
  &--block {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  &--block-header {
    display: flex;
    justify-content: space-between;
  }

  &--block-title {
    font-family: "IBMPlexSans-Bold", sans-serif;
    font-size: 20px;
    color: $primary-fg-color;
    margin: 0 10px 16px 0;
  }

  &--block-content {
    display: flex;
    flex-wrap: wrap;

    .flex {
      display: flex;
    }

    .dashboard-left-status-column {
      padding: 0 30px 0 0 !important;
      align-items: inherit;
    }

    .dashboard-right-status-column {
      padding: 0 !important;
    }
  }

  .live-admins-block, .status-block {
    min-height: 130px;
  }

  #active-users-geo-map {
    background-color: #FFFFFF;
    margin: 16px 0 0 0;
    display: flex;
    justify-content: center;
  }

  &--active-users-wrapper {
    height: 294px;
    width: 540px;
    border-radius: 4px;
    background-color: #FFFFFF;

    .active-users-right-now {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 30px 0 0 0;
      height: 194px;
    }

    .active-users-right-now p:first-of-type {
      color: #000000;
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      margin: 0;
    }

    .active-users-right-now p:nth-of-type(3n) {
      color: #000000;
      font-size: 14px;
      line-height: 16px;
    }

    #active-users-container {
      height: 94px;
      width: 46px;
      margin: 0;
    }

    .active-users-value {
      color: #000000;
      font-size: 80px;
      font-weight: 500;
      line-height: 94px;
    }

    .desktop-mobile-statistics {
      display: none;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 30px 0 0 0;
      padding: 0 30px 30px 30px;

      .desktop-mobile-statistics-labels {
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
      }

      #desktop-statistics-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 10px 0 0;

        span {
          height: 10px;
          width: 10px;
          background-color: #EB572D;
        }

        p {
          color: #005F98;
          font-size: 12px;
          font-weight: bold;
          line-height: 14px;
          margin: 0 0 0 5px;
        }
      }

      #mobile-statistics-label {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
          height: 10px;
          width: 10px;
          background-color: #58B142;
        }

        p {
          color: #005F98;
          font-size: 12px;
          font-weight: bold;
          line-height: 14px;
          margin: 0 0 0 5px;
        }
      }

      .desktop-mobile-statistics-canvas {
        height: 21px;
        width: 100%;
        border-radius: 4px;
        background-color: #EB572D;
        margin: 7px 0 0 0;

        p {
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          padding: 3px 0 0 0;
        }
      }
    }
  }

  &--admin-info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin: 10px 45px 0 0;
    min-width: 78px;

    &--name {
      color: $primary-fg-color;
      margin-top: 4px;
    }

    &--role {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      color: $secondary-fg-color;
    }

    &--popup-role {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #F89407;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
      max-width: 100px;
    }
  }

  // Quick Link
  &--quick-link-block {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  &--quick-link-text {
    color: $primary-fg-color;
    margin-left: 8px;
    cursor: pointer;
  }

  // Status block
  &--status-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $secondary-content-bg;
    padding: 16px 5px 16px 16px !important;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      .dashboard--status-block-number {
        color: $primary-bg-color;
      }
    }
  }

  &--status-block-number {
    font-size: 28px;
    color: #004F57;
  }

  &--status-block-text {
    color: $secondary-fg-color;

    &__highlighted {
      color: $primary-bg-color;
    }
  }

  &--map-hint {
    margin: 8px 0 32px 0;
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    opacity: 0.7;
    text-align: center;
  }
}

.number-of-pending-items,
.number-of-pending-enquiries {
  color: $primary-bg-color;
}
