.add-admin-account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 540px;
  border-radius: 4px;
  margin: 0 80px 0 50px;

  &--top-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 540px;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding: 16px;

    &--account-image-and-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      &--account-image {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 65px;
        height: 100%;
        margin: 0 16px 0 8px;
        border-radius: 50%;
        cursor: pointer;

        &--image-placeholder {
          background-color: #FF0000;
          border-radius: 50%;
          width: 64px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }

      &--account-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &--confirmation,
        &--login,
        &--last-activity {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin: 0 0 14px 0;

        }

        &--label {
          color: #717171;
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
          font-size: 12px;
          line-height: 15px;
          min-width: 65px;
          margin: 0 30px 0 0;
        }

        &--value {
          color: #000000;
          font-family: "IBMPlexSansCondensed-Medium", sans-serif;
          font-size: 12px;
          line-height: 15px;
          cursor: pointer;
        }
      }
    }
  }

  &--fields-section {
    margin: 28px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 540px;

    &--first-name-and-last-name-row,
    &--nickname-and-role-row,
    &--phone-and-mobile-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 12px 0;

      .labeled-input {
        margin: 0 30px 0 0;
        width: 255px;

        &--label {
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        }

        input {
          max-width: 255px;
        }
      }

      .ant-input {
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        color: $primary-fg-color;
      }

      .ant-select {
        min-width: 255px;
        border: 1px solid #E5E5E5;
        border-radius: 3px;
      }
    }

    &--nickname-and-role-row {
      &--label {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 4px 0;
      }

      &--label:after {
        content: "*";
        display: inline-block;
        color: red;
      }

      &--error {
        color: $primary-bg-color;
        font-size: 12px;
        margin: 2px 0 0 0;
      }
    }

    &--email-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 12px 0;

      .labeled-input {
        width: 540px;

        &--label {
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        }
      }

      .ant-input {
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        color: $primary-fg-color;
      }
    }
  }

  &--action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 24px 0 0 0;
    width: 100%;

    &--save-btn {
      height: 32px;
      width: 160px;
      border-radius: 4px;
      background-color: #FF0000;
      margin: 0 8px 0 0;
    }

    &--cancel-btn {
      height: 32px;
      width: 160px;
    }
  }

  .password-reset-btn, .delete-admin-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #B6B6B6;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    line-height: 18px;

    svg {
      margin: 0 8px 0 0;
    }
  }

  .password-reset-btn {
    margin: 0 16px 0 0;
  }

  .confirmation-double-toggle-button,
  .login-double-toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &--left-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--left-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #FF0000;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }
  }

  .ant-upload {
    padding: 1px !important;
  }
}

.add-admin-acc-confirmation-modal {
  width: 100%;

  .ant-modal-header {
    border-bottom: none !important;
    background-color: white !important;
  }

  .ant-modal-footer {
    border-top: none !important;
    background-color: white !important;
    display: flex;
    justify-content: center;
  }

  .ant-modal, .ant-modal-content {
    height: 75px !important;
    width: 400px !important;
    margin: 0 !important;
  }

  &--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
    }

    &--warning {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin: 24px 0 0 0;
    }

    &--description-wrapper {
      margin-top: 24px;
    }

    &--description {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 10px;
      line-height: 12px;
      margin: 0 0 0 0;
    }
  }
}
