%table-span {
  font-size: 12px;
  line-height: 15px;
}

.blog {
  &--add-button {
    margin: 24px 0 0 0;
  }
}

.article, .authors, .categories {
  &--table {
    margin: 25px 0 0 -8px;

    .table--row {
      cursor: pointer;
    }
  }

  &--row-block {

    &__name {
      border-radius: 4px 0 0 4px;
      span {
        @extend %table-span;
        color: #000000;
        max-width: 200px;
      }
    }

    &__url, &__author, &__data, &__description {
        @extend %table-span;
        color: #717171;
    }

    &__actions {
      border-radius: 0 4px 4px 0;
      cursor: pointer;
    }
  }

  &--avatar {
    margin-right: 8px;
  }
}

.add-new-article, .edit-article {
  box-sizing: border-box;
  width: calc(100% - 130px);
  margin: 24px 0 0 0;

  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }
    &.image-holder {
      img {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  &--row-checkboxes {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
    padding: 16px 0;
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }
  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    &--input,
    input {
      width: 350px;
    }
  }

  .add-new-page--page-content-input {
    min-height: 278px;

    .ql-container {
      min-height: 237px;
    }
  }

  .input-with-prefix {
    width: 350px;

    .ant-input {
      padding-left: 5px;
    }

    .ant-input-group-addon {
      padding: 2px 5px 0 5px;
    }
  }

  &--button-holder {
    display: flex;
    margin: 16px 0 40px 0;

    .del-btn-holder {
      margin-left: 15px;

      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }

      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
      }
    }

    .cancel-btn-holder {
      margin-left: 15px;

      .cancel-btn {
        background: none;
        border: none;

        span {
          color: #FF0000;
        }
      }
    }
  }

  &--categories-select-label, &--authors-select-label {
    color: #717171;
    font-size: 14px;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    margin: 8px 0 4px 0;
  }

  &--authors-select-label:after {
    content: "*";
    display: inline-block;
    color: red;
  }

  .ant-select-selection {
    &__choice {
      height: 18px !important;
      font-family: "IBM Plex Sans Condensed", serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px !important;
      text-align: center;
      border-radius: 4px !important;
      background-color: #004F57 !important;
      margin: 6px 0 0 0 !important;

      &__content {
        color: white;
        line-height: 16px !important;
      }

      &__remove {
        color: white !important;
      }
    }
  }
}

.show-blog-on-home {
  margin-left: 10px;

  .ant-switch-small {
    background-color: #FF0000;
  }

  .ant-switch-checked {
    background-color: #1DD303;
  }
}
