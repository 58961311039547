.add-category {
  box-sizing: border-box;

  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }

    &.image-holder {

      img {
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }

  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    max-width: 350px;

    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    input {
      max-width: 350px;
    }
  }

  &--description-input {
    min-height: 161px;
    .ql-container {
      min-height: 161px !important;
    }
  }

  &--description-input {
    max-width: 100% !important;
  }

  &--button-holder {
    display: flex;
    margin: 20px 0 40px 0;

    .del-btn-holder {
      margin-left: 15px;
      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }
      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
        min-width: 116px;
      }
    }
  }

  &--url-input-holder {
    .labeled-input {
      max-width: 600px;

      &--label {
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      }

      input {
        max-width: 600px;
      }
    }

    .ant-input {
      padding-left: 5px;
    }

    .ant-input-group-addon {
      padding: 2px 5px 0 5px;
    }
  }
}
