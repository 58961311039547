.dealers-list {
  margin-bottom: 80px;

  .select, .ant-select, .ant-select-enabled {
    border: 2px solid #F2F2F2;
    width: auto;
  }

  p, span {
    margin: 0;
  }

  .component {

    &--header {
      padding-bottom: 30px;

      &-sort-button {
        margin: 0 16px;
      }

      &-sort-button div:first-child {
        width: 128px;
      }
    }
  }

  &--filters-and-calendar-row {
    display: flex;
    flex-direction: row;
  }

  &--sort-by-status-select,
  &--newest-or-oldest-filter-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      width: 128px;
      margin: 0 16px 0 0;
    }
  }

  &--calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      margin: 0 16px 0 0;
      min-width: 128px;
    }
  }

  &--calendar > .DateRangePicker {
    visibility: hidden;
    height: 0 !important;
  }

  &--calendar-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin: 0 8px 0 0;
    align-items: center;
  }

  &--status-column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;

    &--active-value-is-active,
    &--confirmed,
    &--api-active,
    &--index-active,
    &--merchant-feed-active,
    &--paypal-active,
    &--invoicing-active,
    &--DLZ-access-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      background-color: #4F9A00;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }

    &--active-value-is-pending {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      background-color: #F89407;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }

    &--active-value-is-inactive,
    &--unconfirmed {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      background-color: #FF0000;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }

    &--api-inactive,
    &--index-inactive,
    &--merchant-feed-inactive,
    &--paypal-inactive,
    &--invoicing-inactive,
    &--DLZ-access-inactive {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      background-color: #C2C2C2;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }
  }

  &--details-column {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717171;

    .dealer-detail-column-image img {
      max-height: 80px;
      max-width: 80px;
      margin: 0 8px 0 0;
    }

    .dealer-detail-column-info {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;

      &--business-name {
        color: #000000;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        line-height: 15px;
        margin: 0 0 8px 0;
      }

      &--no-business-name {
        height: 16px;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        background-color: #FF0000;
        border-radius: 3px;
        padding: 0 3px;
        margin: 0 0 2px 0;
        max-width: 90px;
      }

      &--email {
        color: #FF0000;
      }

      &--website {
        color: #FF0000;
        margin: 0 0 8px 0;
      }

      &--phone {
        color: #717171;
      }

      &--mobile {
        color: #717171;
        margin: 0 0 8px 0;
      }

    }
  }

  &--address-column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;

    &_text {
      &:hover {
        color: red;
        transition: all .2s;
      }
    }
  }

  &--options-column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    line-height: 15px;

    &--items-available-and-items-sold,
    &--billing-items-and-invoices {
      margin: 0 0 10px 0;
    }

    &--items-available-row, &--items-sold-row,
    &--billing-items-row, &--invoices-row,
    &--favorite-dealers-row, &--favorite-items-row {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    &--items-available-label, &--items-sold-label,
    &--billing-items-label, &--invoices-label,
    &--favorite-dealers-label, &--favorite-items-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      width: 80px;
      margin: 0 8px 0 0;
    }

    &--items-available-value, &--items-sold-value,
    &--billing-items-value, &--invoices-value,
    &--favorite-dealers-value, &--favorite-items-value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }
  }

  &--enquiries-column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 12px;
    line-height: 15px;

    &--areas-row {
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    &--email-row, &--call-dealer-row, &--phone-row, &--requested-callbacks-row {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin: 0 0 2px 0;
    }

    &--email-label, &--call-dealer-label, &--phone-label, &--requested-callbacks-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      min-width: 105px;
      margin: 0 8px 0 0;
    }

    &--email-value, &--call-dealer-value, &--phone-value, &--requested-callbacks-value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }
  }

  &--specialities-column {

    &--items-wrapper {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &--actions-column {
    svg {
      margin: 0 4px;
      height: 24px;
    }

    &--icons-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 5px;

      &--login-icon {
        color: #7A7A7A;
        font-size: 18px;
      }
    }

    &__delete-icon {
      color: red !important;

      width: 18px;
      height: 18px;

      cursor: pointer;

      svg {
        width: 100%;
        height: 100%;
      };
    }
  }

  &--website-column {
    .ant-switch-small {
      background-color: #FF0000;
    }

    .ant-switch-checked {
      background-color: #1DD303;
    }
  }

  &--switch .ant-switch-checked {
    background-color: #1ED303;
  }

  .table {

    &--column {
      padding: 8px;
    }
  }

  .ant-select-arrow {
    margin: -5px 0 0 0;
  }

  .labeled-input--input {
    width: 100%;
  }

  #delete-dealer-modal {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1000;
  }
}

.delete-dealer-modal {
  width: 100%;

  .ant-modal-header {
    border-bottom: none !important;
    background-color: white !important;
  }

  .ant-modal-footer {
    border-top: none !important;
    background-color: white !important;
  }

  .ant-modal, .ant-modal-content {
    height: 176px !important;
    width: 400px !important;
    margin: 0 !important;
  }

  .delete-modal-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
    }

    &--warning {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin: 24px 0 0 0;
    }
  }

  .delete-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px 0 16px 0;

    .delete-dealer-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 110px;
      margin: 0 8px 0 0;

      svg {
        margin: 0 7.5px 0 0;
      }
    }

    .cancel-delete-dealer-btn {
      width: 110px;
    }
  }
}

.dealer-testimony {
  &__container {
    margin: 20px 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  &__container-descriptions {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }
  &__container-label {
    color: #717171;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;

    text-transform: uppercase;
  }

  &__container-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__container-checkbox-label {
    margin-bottom: 0;

    text-transform: capitalize;
    margin-left: 5px;
  }
}
