.modal-block {
  .custom-modal-header {
    h1 {
      color: #000000;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .search-input {
      width: 100%;
      margin-bottom: 16px;
    }
    .ant-menu {
      overflow: hidden;
    }

    &--info {
      width: 90%;
      margin-bottom: 30px;

      img {
        margin-top: 10px;
        max-height: 150px;
        max-width: 200px;
      }
    }
  }
  .ant-modal {
    &-header,
    &-footer {
      background: #F7F7F9;
    }

    &-header {
      padding: 16px 16px 0;
    }
    &-body {
      .content {
        .table--row {
          border-bottom: 1px solid #E5E5E5;

          & > :first-child {
            padding-left: 16px;
          }
          & > :last-child {
            padding-right: 16px;
          }
          &__highlighted {
            .table--column.ant-checkbox-wrapper {
              background-color: #F1F1F4;
            }
          }
        }
      }
      .wrapper {
        padding: 24px 0;
      }
      padding: 0;
      max-height: calc(100vh - 450px);
      overflow-y: auto;
    }
  }
}

.modal-decline {

  &__title {
    text-align: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  &__form {
    padding: 24px;
  }

  &__text {
    margin-bottom: 0;
  }

  &__btn-group {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 111px 111px;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    text-transform: uppercase;
  }

  &__red-btn {
    background-color: #FF0000 !important;
    color: #FFF !important;
  }
}

.item-history-modal {
  width: 750px !important;

  .ant-modal-content {
    width: 900px;
    height: 775px;
    overflow: hidden;

    .history-actions-container {
      .history-action {
        padding-bottom: 8px;
        margin: 5px 0;

        &--approved-by {
          font-weight: 700;

          &.by-dealer {
            color: #004f57;
          }

          &.by-admin {
            color: #FF0000;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px dashed #000000;
        }
      }
    }
  }

  .ant-modal-body {
    overflow-y: auto;
    padding: 12px;
    height: 720px;
  }

  .ant-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFF;
  }

  &__footer-content {
    background-color: #FFF;
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    padding-top: 2px;
  }

  &__footer-action-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: .7;
    transition: all .2s;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
