.status-switch {
  list-style: none;

  display: flex;
  align-items: center;
  gap: 2px;

  margin: 0;
  padding: 0;

  &__item {
    padding: 0 3px;

    height: 16px;
    color: #FFFFFF;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 15px;

    cursor: pointer;
    background-color: #C2C2C2;

    transition: background-color 0.3s ease;

    &:first-of-type {
      border-radius: 3px 0 0 3px;
    }

    &:last-of-type {
      border-radius: 0 3px 3px 0;
    }
  }
}
