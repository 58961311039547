.add-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $primary-bg-color;
  border-radius: 4px;
  color: #ffffff;
  width: 90px;
  height: 32px;
  justify-content: space-around;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "IBMPlexSansCondensed-Regular", sans-serif;

  &--icon {
    font-size: 30px;
    margin-top: -4px;
  }
}
