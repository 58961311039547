.spam-page {
  padding: 0;
  margin: 0;

  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
  }

  &--table {
    margin: 0 0 24px 0;

    &--id-field,
    &--text-mail-field,
    &--origin-field,
    &--dfa-field {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    &--value-field {
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
  }
}
