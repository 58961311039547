.admin-accounts--accounts-row {
  display: flex;
  flex-wrap: wrap;
}

.account-preview {
  height: 175px;
  border-radius: 4px;
  min-width: 350px;
  background-color: #FFFFFF;
  padding: 8px;
  display: flex;
  margin-right: 30px;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &--account-image {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 65px;
    height: 100%;
    margin: 0 16px 0 8px;
    border-radius: 50%;

    &--image-placeholder {
      background-color: #FF0000;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
    }
  }

  &--account-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 8px 0 0;

    &--name {
      color: #000000;
      font-family: "IBMPlexSans", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 190px;
    }

    &--nickname {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 190px;
    }

    &--email-field {
      margin: 16px 0 0 0;
    }

    &--email-field,
    &--phone-field,
    &--mobile-field,
    &--last-activity-field {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

    }

    &--label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin: 0 4px 0 0;
      min-width: 64px;
    }

    &--value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
    }

    &--role {
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background: #F89407;
      height: 16px;
      border-radius: 3px;
      padding: 0 3px;
      margin: 4px 0 0 0;
    }
  }

  &--account-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &--edit-icon {
      margin: 0 8px 0 0;
      cursor: pointer;
    }
  }
}
