.dealer-item {

  .ant-tabs-tab {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 24px 0 0;
  }
}
