.dealer-notes-tab {
  padding: 0;
  margin: 0;

  &--add-button {
    margin: 24px 0 0 0;
  }

  &--table {
    margin: 24px 0;
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--empty-data-message {
    margin: 24px 0 0 0;
    font-size: 20px;
    color: #FF0000;
  }
}

.add-new-note {
  padding: 0;
  margin: 0;

  &--title {
    color: #000000;
    font-family: "IBMPlexSans-Bold", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    margin: 0 0 16px 0;
  }

  &--set-reminder-field {

    &--label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }

    .DateInput_input {
      height: 32px;
      width: 130px;
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &--status-field {
    .labeled-input--input {
      width: 350px;
    }
  }

  &--note-field {

    &--label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }

    &--label:after {
      content: "*";
      display: inline-block;
      color: red;
    }
  }

  &--action-buttons {
    display: flex;
    flex-direction: row;
    margin: 24px 0 32px 0;

    &--save-button {
      margin: 0 8px 0 0;
    }

    .ant-btn {
      height: 32px;
      width: 160px;
    }
  }

  &--date-picker {
    display: inline-block;
    width: 350px;
    padding: 2px;

    .DateInput {
      width: 346px;
    }

    .DateInput_input {
      height: 30px;
      width: 346px;
    }

    &__error {
      border-color: red;
    }
  }
}
