.dealer-items-tab {
  &--filters-container {
    display: flex;

    .labeled-input {
      width: 200px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &--title {
    color: #000000;
    font-family: "IBMPlexSans-Bold", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    margin: 24px 0 24px 0;
  }

  &--table {
    &--row {
      font-size: 12px;
    }

    &--status-column {
      padding: 7px 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &--row {
        margin-top: 5px;
      }

      &--label {
        width: 30px;
      }

      &--pending-status {
        height: 16px;
        width: 64px;
        border-radius: 4px 4px 0 0;
        background-color: #C2C2C2;
        margin: 0 0 1px 0;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      &--pending-status-active {
        height: 16px;
        width: 64px;
        border-radius: 4px 4px 0 0;
        background-color: #F89407;
        margin: 0 0 1px 0;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      &--approved-status {
        height: 16px;
        width: 64px;
        background-color: #C2C2C2;
        margin: 0 0 1px 0;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      &--approved-status-active {
        height: 16px;
        width: 64px;
        background-color: #4F9A00;
        margin: 0 0 1px 0;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      &--declined-status {
        height: 16px;
        width: 64px;
        border-radius: 0 0 4px 4px;
        background-color: #C2C2C2;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      &--declined-status-active {
        height: 16px;
        width: 64px;
        border-radius: 0 0 4px 4px;
        background-color: #FF0000;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Bold", sans-serif;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }
    }

    &--item-column {
      display: flex;

      &--status {
        width: calc(100% - 50px);
      }

      .status-panel {
        margin: 0 0 0 10px;

        &--item {
          color: #FFFFFF;
          font-family: "IBMPlexSansCondensed-Medium", sans-serif;
          font-size: 12px;
          font-weight: bold;
          line-height: 15px;
          text-align: center;
          max-width: 65px;
        }
      }

      &--title {
        color: #000000;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        line-height: 15px;
      }

      img {
        height: 32px;
        width: 32px;
        border-radius: 2px;
        margin: 11px 17px 11px 0;
      }
    }

    &--price-column {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    &--ref-column, &--created-at-column {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--empty-data-message {
    margin: 24px 0 0 0;
    font-size: 20px;
    color: #FF0000;
  }
}
