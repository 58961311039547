.tafl {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &--content {
    display: flex;
    justify-content: flex-end;
  }

  &--table {
    flex: 1;

    &__shrink {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(66.6% - 15px);
    }
  }

  &--form {
    width: 33.333%;
    margin-left: 15px;
    margin-top: 40px;
    transition: width .3s, margin .3s;

    .general-form {
      background-color: #ffffff;
    }

    &__full {
      width: 100%;
      margin: 0;

      .general-form {
        background-color: transparent;
      }
    }
  }
}
