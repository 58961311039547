.dealer-details-tab {
  display: flex;
  flex-direction: row;
  margin: 36px 0 0 0;

  &--name-and-contact-details {

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 0 0 16px 0;
    }

    &--fields-row {
      display: flex;
    }

    &--left-and-middle-column-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
    }

    &--specialities-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
    }


    &--fields {

      &--left-column {
        margin: 0 30px 0 0;
      }

      &--left-column, &--middle-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }

      &--right-column {
        margin: 23px 0 0 15px;
      }
    }

    .left-column {
      &--title-input, &--first-name-input, &--last-name-input {
        min-width: 255px;
      }
    }

    .middle-column {
      &--telephone-input, &--mobile-input {
        min-width: 255px;
      }
    }
  }

  &--description-field-label, &--expertise-field-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 4px 0;
  }

  &--expertise-field-label {
    margin: 4px 0 0 0;
  }

  #dealer-details-tab--description-field {
    height: 76px;
    width: 540px;
  }

  #dealer-details-tab--expertise-field {
    min-height: 32px;
    width: 540px;
    height: auto;
  }

  .ant-select-selection {

    &--multiple {
      width: 350px;
      height: 50px;
    }

    &__choice {
      height: 18px !important;
      font-family: "IBM Plex Sans Condensed", serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px !important;
      text-align: center;
      border-radius: 4px !important;
      background-color: #004F57 !important;
      margin: 6px 0 0 0 !important;

      &__content {
        color: white;
        line-height: 16px !important;
      }

      &__remove {
        color: white !important;
      }
    }
  }

  &--business-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 16px 0;
    }

    &--content {

      &--business-name-input {
        width: 540px;
      }

      &--wesite-and-email-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 8px 0 0 0;
      }

      &--website-input, &--email-input {
        width: 255px;
      }

      &--website-input {
        margin: 0 30px 0 0;

        .ant-input-group-wrapper {
          padding: 0 !important;
          border: none !important;
        }
      }
    }
  }

  &--featured-dealer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 16px 0;
    }

    &--content {

      &--checkbox-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
      }

      &--checkbox-label {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 0 8px;
      }

      #dealer-details-tab--featured-summary-field {
        height: 94px;
        width: 540px;
      }
    }
  }

  &--paypal-details,
  &--certified-dealer,
  &--approved-listing,
  &--sales-count {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 16px 0;
    }

    &--content {

      &--pay-pal-status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
      }

      &--pay-pal-email-input {
        min-width: 255px;
      }

      .ant-radio-wrapper:first-of-type {
        margin: 0 20px 0 0;
      }
    }
  }

  &--dealer-website {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 16px 0;
    }

    &--content {

      &--dealer-website-status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
      }

      .ant-radio-wrapper:first-of-type {
        margin: 0 20px 0 0;
      }
    }
  }

  &--invoicing {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 16px 0;
    }

    &--content {

      &--invoicing-status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
      }

      .ant-radio-wrapper:first-of-type {
        margin: 0 20px 0 0;
      }

      &--fields {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        margin: 0 0 32px 0;

        &--left-row {

          &--invoicing-company-input {
            min-width: 540px;
          }

          &--invoicing-address-label {
            color: #717171;
            font-family: "IBMPlexSansCondensed-Regular", sans-serif;
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 4px 0;
          }

          #dealer-details-tab--invoicing-address-field {
            height: 100px;
            width: 540px;
          }
        }

        &--right-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;

          &--left-column, &--right-column {
            padding: 7px 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          &--left-column {
            margin: 0 30px 0 0;
          }

          &--postcode-input {
            min-width: 255px;
          }
        }
      }
    }
  }

  &--town-or-city-select-label,
  &--country-select-label,
  &--invoice-day-select-label,
  &--region-select-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 4px 0;
  }

  &--town-or-city-select,
  &--country-select,
  &--invoice-day-select,
  &--region-select {

    .ant-select {
      min-width: 255px;
      margin: 0 0 8px 0;
      border: 1px solid #E5E5E5;
      border-radius: 3px;
    }
  }

  &--action-buttons {
    display: flex;
    flex-direction: row;
    margin: 0 0 32px 0;

    &--save-button {
      margin: 0 8px 0 0;
    }

    .ant-btn {
      height: 32px;
      width: 160px;
    }
  }

  &--specialities-select-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 4px 0 8px 0;
  }
}

.patch-dealer-details-modal {
  width: 100%;

  .ant-modal-header {
    border-bottom: none !important;
    background-color: white !important;
  }

  .ant-modal-footer {
    border-top: none !important;
    background-color: white !important;
    display: flex;
    justify-content: center;
  }

  .ant-modal, .ant-modal-content {
    height: 75px !important;
    width: 400px !important;
    margin: 0 !important;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
    }

    &--warning {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin: 24px 0 0 0;
    }
  }
}
