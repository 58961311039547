.offers {
  &-wrapper {
    margin-top: 24px;
    margin-bottom: 30px;
  }
  &--table {
    margin: 25px 0 0 -8px;

    .table {
      &--column {
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        color: #717171;
        font-size: 12px;

        &.bold {
          color: #000000;
          font-family: IBMPlexSansCondensed-Medium, sans-serif;
        }
      }
    }
  }
  &--add {
    max-width: 826px;
    margin-bottom: 30px;

    &__small {
      max-width: 540px;
    }

    .image-upload {
      margin-top: 30px;
    }
  }
}
