.admin {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-content-bg;
  height: 100%;

  .admin-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow-y: auto;
  }
}
