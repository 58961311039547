.home-and-living {
  padding: 0;
  margin: 0;

  &--header {
    justify-content: space-between;
  }

  &--table {
    margin: 24px 0;

    .table--header, .table--row {
      margin-left: -10px;
    }

    &--actions-column {
      display: flex;
      flex-direction: row;
    }
  }

  &--table-icon {
    margin: 2px 16px 2px 0;
  }

  &--add-entity-modal {

    &--action-buttons {
      padding: 10px 0;
      background-color: #F7F7F9;
      position: sticky;
      bottom: 0;
    }

    &--save-button {
      margin: 0 20px 0 0;
    }
  }
}

.add-edit-home-and-living-entity {
  padding: 0;
  margin: 0;

  &--title-and-url-row {
    margin: 0 0 8px 0;
  }

  .override-inputs {
    margin: 0 0 30px 0;
  }

  .general-form--button-holder {
    margin: 16px 0 24px 0;
  }

  .home-and-living-text-area {
    .labeled-input--input {
      min-height: 94px;
    }
  }

  .image-upload--uploader {
    min-width: 256px;
  }

  &--image-picker {
    margin: 0 30px 0 0;
  }

  .general-form--row__align-start {
    margin: 24px 0 30px 0;
  }

  &--bottom-description {
    margin: 30px 0 0 0;
  }

  .disabled-input {
    padding: 6.5px 16.5px;
    background-color: $primary-content-bg;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    white-space: pre-line;
    min-height: 33px;
    line-height: 18px;
    color: #000000;
    cursor: text;
    overflow: hidden;
    text-overflow: ellipsis;

    &.no-assigned {
      cursor: default;
      text-align: center;

      span {
        opacity: .5;
      }
    }

    &.many {
      cursor: default;
      position: relative;
      padding-right: 35px;

      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: none;
      }
      &:not(:first-child) {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .add-category {
    color: #fff;
    min-width: 16px;
    height: 16px;
    font-size: 18px;
    background-color: $primary-bg-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-left: 8px;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
  }

  .ant-collapse-no-arrow {
    background-color: #F7F7F9;
  }

  .ant-collapse {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .ant-collapse-borderless,
  .ant-collapse {
    background-color: transparent;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }
}

.category-modal--id-field {
	color: #717171;
	font-family: "IBMPlexSansCondensed-Regular", sans-serif;
	font-size: 12px;
	line-height: 15px;
}
