.pinterest {
  &__main-info {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 15px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 5px;
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }
}
