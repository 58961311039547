.dealer-specialities {
  p, span {
    margin: 0;
  }

  .table {
    margin: 24px 0 0 0;

    &--column {
      padding: 8px;
    }
  }

  &--title-column {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--name-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--dealers-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--description-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--actions {
    padding: 0 !important;
  }

  &--action {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    padding: 8px;
  }

  .ant-select-arrow {
    margin: -5px 0 0 0;
  }
}
