.dealer-invoices-tab {

  .select, .ant-select, .ant-select-enabled {
    border: 2px solid #F2F2F2;
    width: auto;
  }

  p, span {
    margin: 0;
  }

  .component {

    &--header {
      padding-bottom: 30px;
    }
  }

  &--filters-and-calendar-row {
    display: flex;
    flex-direction: row;
    margin: 24px 0 0 0;
  }

  &--sort-by-status-select {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      width: 128px;
      margin: 0 16px 0 0;
    }
  }

  &--calendar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      margin: 0 16px 0 0;
      min-width: 128px;
    }
  }

  &--calendar > .DateRangePicker {
    visibility: hidden;
    height: 0 !important;
  }

  &--actions-column {

    svg {
      margin: 0 8px 0 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .table {

    &--column {
      padding: 8px;
    }
  }

  .ant-select-arrow {
    margin: -5px 0 0 0;
  }

  .labeled-input--input {
    width: 100%;
  }

  #delete-invoice-modal {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 1000;
  }

  &--status-column {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &--status-is-active {
      height: 15px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }

    &--status-is-pending {
      height: 15px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #F89407;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }

    &--status-is-inactive {
      height: 15px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #FF0000;
      border-radius: 3px;
      padding: 0 3px;
      margin: 0 0 2px 0;
    }
  }

  &--invoice-number-column,
  &--method-column,
  &--invoice-date-column,
  &--due-date-column,
  &--lines-column,
  &--id-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--empty-data-message {
    margin: 24px 0 0 0;
    font-size: 20px;
    color: #FF0000;
  }
}

.delete-invoice-modal {
  width: 100%;

  .ant-modal-header {
    border-bottom: none !important;
    background-color: white !important;
  }

  .ant-modal-footer {
    border-top: none !important;
    background-color: white !important;
  }

  .ant-modal, .ant-modal-content {
    height: 176px !important;
    width: 400px !important;
    margin: 0 !important;
  }

  .delete-modal-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
    }

    &--warning {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
      margin: 24px 0 0 0;
    }
  }

  .delete-modal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 40px 0 16px 0;

    .delete-invoice-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 110px;
      margin: 0 8px 0 0;

      svg {
        margin: 0 7.5px 0 0;
      }
    }

    .cancel-delete-invoice-btn {
      width: 110px;
    }
  }
}
