.items-list {
  &__create-artisan-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    .input-field--input-label {
      text-transform: uppercase;
    }

    .labeled-input,
    .input-with-prefix {
      width: 100%;
    }
  }

  &__create-artisan-full-width {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &__create-artisan-btn-group {
    border-top: 1px solid #d9d9d9;
    padding: 20px 24px 0;
    margin: 0 -24px;

    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 16px;
    justify-content: flex-end;

    .ant-btn-ghost span {
      color: #FF0000;
    }
  }

  &__create-artisan-select {
    width: 100%;
    border-radius: 4px;
  }

  &__create-artisan-error {
    font-size: 12px;
    line-height: 14px;
    margin-top: 2px;
    margin-bottom: 0;
    color: #FF0000;
  }
}
