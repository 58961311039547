.custom-drp {
  margin-right: 16px;

  & > .DayPicker.DayPicker_1:before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.3);
  }

  & > .DayPicker.DayPicker_1 > div {
    position: relative;
    z-index: 1;
    background-color: #fff;
  }

  &--select {
    width: auto;
    min-width: 100px;
  }
}
