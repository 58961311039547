// Montserrat

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Lato

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../fonts/Lato/Lato-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Roboto

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Oswald

@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Oswald";
  src: url("../fonts/Oswald/Oswald-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Russo One

@font-face {
  font-family: "Russo One";
  src: url("../fonts/Russo_One/RussoOne-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Libre Baskerville

@font-face {
  font-family: "Libre Baskerville";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Libre Baskerville";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Dancing Script

@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/Dancing_Script/DancingScript-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/Dancing_Script/DancingScript-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/Dancing_Script/DancingScript-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Dancing Script";
  src: url("../fonts/Dancing_Script/DancingScript-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Lobster

@font-face {
  font-family: "Lobster";
  src: url("../fonts/Lobster/Lobster-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Playball

@font-face {
  font-family: "Playball";
  src: url("../fonts/Playball/Playball-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Allura

@font-face {
  font-family: "Allura";
  src: url("../fonts/Allura/Allura-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Lovers Quarrel

@font-face {
  font-family: "Lovers Quarrel";
  src: url("../fonts/Lovers_Quarrel/LoversQuarrel-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Sail

@font-face {
  font-family: "Sail";
  src: url("../fonts/Sail/Sail-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Euphoria Script

@font-face {
  font-family: "Euphoria Script";
  src: url("../fonts/Euphoria_Script/EuphoriaScript-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Kaushan Script

@font-face {
  font-family: "Kaushan Script";
  src: url("../fonts/Kaushan_Script/KaushanScript-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Arizonia

@font-face {
  font-family: "Arizonia";
  src: url("../fonts/Arizonia/Arizonia-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Monsieur La Doulaise

@font-face {
  font-family: "Monsieur La Doulaise";
  src: url("../fonts/Monsieur_La_Doulaise/MonsieurLaDoulaise-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Big Shoulders Stencil Text

@font-face {
  font-family: "Big Shoulders Stencil Text";
  src: url("../fonts/Big_Shoulders_Stencil_Text/BigShouldersStencilText-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Big Shoulders Stencil Text";
  src: url("../fonts/Big_Shoulders_Stencil_Text/BigShouldersStencilText-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Big Shoulders Stencil Text";
  src: url("../fonts/Big_Shoulders_Stencil_Text/BigShouldersStencilText-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Big Shoulders Stencil Text";
  src: url("../fonts/Big_Shoulders_Stencil_Text/BigShouldersStencilText-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Big Shoulders Stencil Text";
  src: url("../fonts/Big_Shoulders_Stencil_Text/BigShouldersStencilText-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Monoton

@font-face {
  font-family: "Monoton";
  src: url("../fonts/Monoton/Monoton-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Buda

@font-face {
  font-family: "Buda";
  src: url("../fonts/Buda/Buda-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

// Vast Shadow

@font-face {
  font-family: "Vast Shadow";
  src: url("../fonts/Vast_Shadow/VastShadow-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Forum

@font-face {
  font-family: "Forum";
  src: url("../fonts/Forum/Forum-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Tangerine

@font-face {
  font-family: "Tangerine";
  src: url("../fonts/Tangerine/Tangerine-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Tangerine";
  src: url("../fonts/Tangerine/Tangerine-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Arapey

@font-face {
  font-family: "Arapey";
  src: url("../fonts/Arapey/Arapey-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Special elite

@font-face {
  font-family: "Special Elite";
  src: url("../fonts/Special_Elite/SpecialElite-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Josefin Slab
@font-face {
  font-family: "Josefin Slab";
  src: url("../fonts/Josefin_Slab/JosefinSlab-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Slab";
  src: url("../fonts/Josefin_Slab/JosefinSlab-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Slab";
  src: url("../fonts/Josefin_Slab/JosefinSlab-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Slab";
  src: url("../fonts/Josefin_Slab/JosefinSlab-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Slab";
  src: url("../fonts/Josefin_Slab/JosefinSlab-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Spartan

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan/Spartan-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan/Spartan-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan/Spartan-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan/Spartan-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Spartan";
  src: url("../fonts/Spartan/Spartan-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// EB Garamond

@font-face {
  font-family: "EB Garamond";
  src: url("../fonts/EB_Garamond/EBGaramond-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "EB Garamond";
  src: url("../fonts/EB_Garamond/EBGaramond-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "EB Garamond";
  src: url("../fonts/EB_Garamond/EBGaramond-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "EB Garamond";
  src: url("../fonts/EB_Garamond/EBGaramond-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Nanum Gothic

@font-face {
  font-family: "Nanum Gothic";
  src: url("../fonts/Nanum_Gothic/NanumGothic-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nanum Gothic";
  src: url("../fonts/Nanum_Gothic/NanumGothic-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Caramel

@font-face {
  font-family: "Caramel";
  src: url("../fonts/Caramel/Caramel-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// Cinzel Decorative

@font-face {
  font-family: "Cinzel Decorative";
  src: url("../fonts/Cinzel_Decorative/CinzelDecorative-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Cinzel Decorative";
  src: url("../fonts/Cinzel_Decorative/CinzelDecorative-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Playfair Display SC

@font-face {
  font-family: "Playfair Display SC";
  src: url("../fonts/Playfair_Display_SC/PlayfairDisplaySC-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Playfair Display SC";
  src: url("../fonts/Playfair_Display_SC/PlayfairDisplaySC-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Chakra Petch

@font-face {
  font-family: "Chakra Petch";
  src: url("../fonts/Chakra_Petch/ChakraPetch-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch";
  src: url("../fonts/Chakra_Petch/ChakraPetch-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch";
  src: url("../fonts/Chakra_Petch/ChakraPetch-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch";
  src: url("../fonts/Chakra_Petch/ChakraPetch-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Chakra Petch";
  src: url("../fonts/Chakra_Petch/ChakraPetch-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Cinzel

@font-face {
  font-family: "Cinzel";
  src: url("../fonts/Cinzel/Cinzel-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Cinzel";
  src: url("../fonts/Cinzel/Cinzel-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Cinzel";
  src: url("../fonts/Cinzel/Cinzel-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Crimson Text

@font-face {
  font-family: "Crimson_Text";
  src: url("../fonts/Crimson_Text/CrimsonText-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Crimson_Text";
  src: url("../fonts/Crimson_Text/CrimsonText-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Crimson_Text";
  src: url("../fonts/Crimson_Text/CrimsonText-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Windsong

@font-face {
  font-family: "Windsong";
  src: url("../fonts/Windsong/WindSong-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Windsong";
  src: url("../fonts/Windsong/WindSong-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

// Bodoni Classico

@font-face {
  font-family: "Bodoni Classico";
  src: url("../fonts/Bodoni_Classico/BodoniModa_72pt-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Bodoni Classico";
  src: url("../fonts/Bodoni_Classico/BodoniModa_72pt-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Bodoni Classico";
  src: url("../fonts/Bodoni_Classico/BodoniModa_72pt-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Bodoni Classico";
  src: url("../fonts/Bodoni_Classico/BodoniModa_72pt-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

// Classic Roman

@font-face {
  font-family: "Classic Roman";
  src: url("../fonts/Classic_Roman/ClassicRoman-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Classic Roman";
  src: url("../fonts/Classic_Roman/ClassicRoman-Bold.ttf");
  font-weight: 500;
  font-display: swap;
}

// Explorer

@font-face {
  font-family: "Explorer";
  src: url("../fonts/Explorer/Explorer-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

// IBM Plex

@font-face {
  font-family: "IBMPlexSans-Bold";
  src : url("../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf")
}

@font-face {
  font-family: "IBMPlexSansCondensed-Regular";
  src : url("../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf")
}

@font-face {
  font-family: "IBMPlexSansCondensed-Medium";
  src : url("../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Medium.ttf")
}

@font-face {
  font-family: "IBMPlexSansCondensed-Bold";
  src : url("../fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Bold.ttf")
}
