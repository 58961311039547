.system-preferences {
  .general-form {
    &--checkbox-holder {
      .ant-checkbox-wrapper {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      }
    }
  }
}
