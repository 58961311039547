.list-of-orders {
  .select, .ant-select, .ant-select-enabled {
    width: auto;
  }

  .ant-select-clear {
    top: 10px;
  }

  p, span {
    margin: 0;
  }

  &--payment-column {
    display: block;
  }

  .component {
    &--header {
      padding-bottom: 30px;

      &-sort-button {
        margin: 0 16px;
      }

      &-sort-button div:first-child {
        width: 128px;
      }
    }
  }

  &--sort-buttons-and-calendar-row {
    display: flex;
    flex-direction: row;
  }

  &--sort-by-status-btn,
  &--sort-by-payment-status-btn,
  &--sort-by-dealer-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      width: 128px;
      margin: 0 16px 0 0;
    }

    .ant-select-arrow {
      margin: -5px 0 0 0;
    }
  }

  &--sort-by-date-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      color: #717171;
      font-size: 14px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 8px 0 0;
    }

    .select {
      margin: 0 16px 0 0;
      min-width: 128px;
    }

    .ant-select-arrow {
      margin: -5px 0 0 0;
    }
  }

  &--sort-by-date-btn > .DateRangePicker {
    visibility: hidden;
    height: 0 !important;
  }

  .search-input {
    margin-left: 16px;
  }

  .table {
    &--row {
      font-size: 12px;
      margin: 0 0 4px 0 !important;
    }
    &--column {
      flex-shrink: 0;
      flex-wrap: nowrap;
      box-sizing: border-box;
      background-color: inherit !important;
      padding: 4px 4px 4px 8px;
      word-break: break-word;
    }
  }

  &--content__row {
    border-radius: 4px;
    background-color: #FFFFFF;
    margin: 0 0 4px 0;
  }

  &--order-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #order-column-id-value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    #order-column-created-date-value {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--item-details-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .item-details-column--image {
      max-height: 80px;
      max-width: 80px;
      border-radius: 2px;
      padding: 0 8px;
    }

    .item-details-column--info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      #info-ref-value {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 12px;
      }

      #info-title-value {
        color: #000000;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
      }

      #info-item-total-value, #info-shipping-value {
        display: flex;
        flex-direction: row;
        justify-content: center;

        p {
          color: #717171;
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
          font-size: 12px;
          width: 50px;
        }

        span {
          color: #000000;
          font-family: "IBMPlexSansCondensed-Medium", sans-serif;
          font-size: 12px;
          margin: 0 0 0 14px;
        }
      }
    }
  }

  &--dealer-column {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
  }

  &--customer-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    #customer-column-name-value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    #customer-column-email-value {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--total-column {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--status-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .status-column--paid {
        height: 16px;
        width: 31px;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 12px;
        text-align: center;
        background-color: #4F9A00;
        border-radius: 4px;
        padding: 1px 0 0 0;
        margin: 0 4px 4px 0;
    }
    .status-column--refunded {
        height: 16px;
        width: 58px;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 12px;
        text-align: center;
        background-color: #F89305;
        border-radius: 4px;
        padding: 1px 0 0 0;
        margin: 0 4px 4px 0;
    }
    .status-column--bounced {
        height: 16px;
        width: 53px;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 12px;
        text-align: center;
        background-color: #F70501;
        border-radius: 4px;
        padding: 1px 0 0 0;
        margin: 0 4px 4px 0;
    }

    .status-column--completed {
      height: 16px;
      width: 64px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 4px;
      padding: 1px 0 0 0;
      margin: 0 4px 4px 0;
  }

    .status-column--statuses-row {
      display: flex;
      flex-direction: column;
      width: auto;
    }

    #status-column--cart-id {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
      height: auto;
    }
  }

  &--actions-column {
    .order-header-icon, .order-history-icon {
      margin: 0 8px 0 0;
    }
  }

  .ant-select-arrow {
    margin: -5px 0 0 0;
  }
}
