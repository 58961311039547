html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  overflow-y: auto;

}
html body {
  font-family: IBMPlexSansCondensed-Medium, sans-serif;
  line-height: initial;
  font-variant: initial;
  height: 100%;
}

b,
strong {
  font-family: "IBMPlexSansCondensed-Bold", sans-serif;
}

#root {
  display: flex;
  flex: 1;
  height: 100%;
}

#modal {
  position: absolute;
}

iframe {
  display: none;
}
