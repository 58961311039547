.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 200px 0 0 0;

  svg {
    opacity: 0.2;
  }

  div {
    color: #C2C2C2;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0 0 8px 0;
  }
}
