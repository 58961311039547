.pointer {
  cursor: pointer;
}

.text-center {
  text-align: center !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}
