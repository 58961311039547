.enquiries {
  &--select {
    margin-right: 16px;
    width: auto;
    min-width: 210px;
  }

  &--column-cell {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &--filter-holder {
    & > .DateRangePicker {
      visibility: hidden;
      height: 0 !important;
    }
  }

  .email-enq {
    &--table-row {
      margin-bottom: 0;
    }

    &--info {
      width: 100%;
      background-color: #ffffff;
      padding: 0 8px 8px;
      margin-bottom: 4px;
      display: flex;
    }

    &--status-block {
      width: 120px;
      display: flex;
      flex-direction: column;
      padding-right: 12px;
      flex-shrink: 0;
    }

    &--status {
      text-align: center;
      margin-bottom: 1px;
      background-color: #C2C2C2;
      color: #FFFFFF;
      font-size: 12px;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      cursor: pointer;

      &:nth-of-type(1) { border-radius: 4px 4px 0 0 }
      &:last-of-type { border-radius: 0 0 4px 4px }

      &.active {
        background-color: #F89407;
      }
    }

    &--info-block {
      padding-top: 10px;
    }

    &--text {
      padding: 1px 0;
    }

    &--label {
      width: 100px;
      min-width: 100px;
      color: $secondary-fg-color;
      font-size: 12px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      display: inline-block;
    }

    &--value {
      color: $primary-fg-color;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }

    &--item-block {
      flex-wrap: nowrap;
    }

    &--row-image {
      margin-right: 8px;
      flex-shrink: 0;
    }

    &--badge {
      background-color: #4F9A00;
      border-radius: 4px;
      width: 100%;
      font-size: 12px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Bold", sans-serif;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--reject-button {
      margin-right: 16px;
    }

    &--reject-button-icon {
      margin-right: 8px;
      vertical-align: sub;
    }

    &--dealer-column {
      cursor: pointer;
    }

    &--filter-by-dealer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 15px;

      &--label {
        color: $secondary-fg-color;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 4px;
        flex-shrink: 0;
        margin: 0 5px 0 0;
      }
    }
  }

  .phone-enq {
    &--status {
      border-radius: 4px;
      background-color: #4F9A00;
      color: #FFFFFF;
      font-size: 12px;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      padding: 0 6px;

      &.pending {
        background-color: #F89407;
      }
      &.hung {
        background-color: #FF0000;
      }
    }
  }

  .blacklists-enq {
    &--add-button {
     margin-right: 16px;
    }
  }

  .component--header {
    margin: 24px 0 0 0;
  }
}

.email-enq--dropdown {
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.15);
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}

.email-enq--dropdown-row {
  margin: 8px 0;
  cursor: pointer;
  color: $primary-fg-color;
  font-size: 14px;
  line-height: 18px;
}
