.add-dealer-news-item {
    box-sizing: border-box;

  .SingleDatePickerInput {
      width: 350px !important;
      height: 32px !important;
  }

  .DateInput {
    width: 348px;
    height: 30px
  }

  #add-dealer-news-item--date-field {
    height: 18px;
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    margin: 4px 0 0 0;
  }

  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }

  &--date-field-title {
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    color: #717171;
  }

  &--date-field-title:after {
    content: "*";
    display: inline-block;
    color: red;
  }

  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    max-width: 350px;

    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    input {
      max-width: 350px;
    }
  }

  &--content-input {
    min-height: 161px;
    .ql-container {
      min-height: 161px !important;
    }
  }

  &--content-input {
    max-width: 100% !important;
  }

  &--button-holder {
    display: flex;
    margin: 20px 0 40px 0;

    .del-btn-holder {
      margin-left: 15px;
      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }
      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
        min-width: 116px;
      }
    }
  }
}

.add-dealer-news {
  &__upload-btn {
    width: 104px;
    height: 104px;

    background-color: #fafafa;
    border: 1px dashed #d9d9d9;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_disabled {
      cursor: disabled;
      opacity: .3;
    }
  }

  &__upload-icon {
    width: 22px;
    height: 22px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__uploader-container {
    border: 1px dashed #d9d9d9;
    background-color: #FFF;
    padding: 10px 10px 0 10px;

    .ant-upload.ant-upload-select-picture-card {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;

      border: none;
    }

    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      width: max-content;
      margin-bottom: 8px;
    }

    .ant-upload-list.ant-upload-list-picture-card {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
}
