.carousel-footer {
  margin-top: 16px;
  text-align: center;

  & .name {
    font-size: 12px;
    font-weight: bold;
    font-family: "IBM Plex Sans Condensed", serif;
    background-color: #717171;
    color: #FFFFFF;
    padding: 1px 6px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .navigation {
    svg {
      cursor: pointer;
    }

    .anticon {
      font-size: 18px !important;
      color: #ffffff !important;
      width: 50px !important;
    }
  }

  .mr-24p {
    margin-right: 24px;
  }

  .mr-16p {
    margin-right: 16px;
  }
}
