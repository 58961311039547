.admin-accounts {
  &--add-button {
    margin: 24px 0 0 0;
    color: #FFFFFF;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 14px;
    line-height: 18px;
  }

  &--accounts-row {
    margin: 24px 0;
  }

  &--pagination {
    display: flex;
    justify-content: center;
  }
}

.admin-accounts-history--filters-row {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 20px;
  padding: 10px 0;

  .custom-drp {
    margin: 0;
  }
}

.admin-accounts-history--actions-filter {
  width: 200px;
}

.admin-accounts-history--date-filter {
  width: 150px;
}

.admin-accounts-history--no-history {
  margin-top: 50px;
  font-size: 16px;
  color: $primary-bg-color;
  text-align: center;
}
