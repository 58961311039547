.overview {
  &--block {
    margin-bottom: 24px;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }

    &-title {
      margin-bottom: 0;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      color: #000000;
    }
  }

  &--status-block {
    flex-direction: column;
    align-items: flex-start;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 16px;
    word-break: break-word;

    &.flex {
      display: flex;
    }

    &:hover {
      cursor: pointer;

      .overview--status-block-number {
        color: $primary-bg-color;
      }
    }

    &-number {
      font-size: 28px;
      color: #004F57;
    }

    &-text {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    .body {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .down {
        color: $primary-bg-color;
      }

      .up {
        color: #4F9A00;
      }

      .down,
      .up {
        display: inline-block;
        line-height: 0;

        i {
          font-size: 18px;
          margin-right: 6px;
        }
      }
    }
  }
}
