.search-input {
  height: 32px;
  width: 240px;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  background-color: $secondary-content-bg;
  cursor: text;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  &--icon {
    margin: 0 10px;
  }

  &--input {
    border: none;
    height: 32px;
    box-sizing: border-box;
    flex: 1;
    background-color: transparent;
  }

  input:placeholder-shown {
    opacity: 0.5;
    color: #717171;
    font-size: 14px;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
  }
}
