.root-container {
  width: 85%;
  margin: 36px auto;
}

.template-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.red-color {
  color: #ee3b3c !important;
}

.fw-700 {
  font-weight: 700;
}

.grey-block {
  width: 87%;
  margin: 30px auto;
  background: #ececec1a;
  padding: 10px 15px;
  border: 0.5px solid rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border-radius: 4px;
}

.grey-block-title {
  font-size: 24px;
  line-height: 24px;
  color: #666666;
  text-align: center;
  margin: 8px 0;
}

table {
  width: 100%;
}

.info-row-title {
  line-height: 24px;
  margin: 8px 0;
}

.info-row-value {
  font-weight: 600;
  line-height: 24px;
  margin: 8px 0 0 10px;
  text-align: end;
  word-break: break-word;
}

.text-align-center {
  text-align: center;
}

.system-message {
  margin-top: 15px;
  color: #999999;
  line-height: 20px;
}

.copyright {
  font-size: 12px;
  line-height: 16px;
  color: #999999;
}
