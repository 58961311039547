.auth {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-content-bg;

  .auth-block {
    background-color: $secondary-content-bg;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 410px;

    &--logo {
      margin-bottom: 40px;
    }

    &--form-error {
      height: 33px;
      width: 100%;
      border: 1px solid $primary-bg-color;
      border-radius: 4px;
      background-color: rgba(255, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $highlight-fg-color;
      font-size: 12px;
    }

    &--form-error-icon {
      margin-right: 10px;
    }

    &--form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }

    &--input-header {
      display: flex;
      width: 100%;
      margin: 8px 0 4px;
      justify-content: space-between;
    }

    &--input-label {
      color: $secondary-fg-color;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;

      &__highlighted {
        color: $highlight-fg-color;
      }
    }

    &--form-input {
      height: 33px;
      width: 100%;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      background-color: $primary-content-bg;
      padding: 0 12px;
      color: $primary-fg-color;
    }

    &--form-checkbox {
      align-self: flex-start;
      margin-top: 10px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    &--form-submit {
      height: 32px;
      width: 160px;
      margin-top: 40px;
    }

    &--success {
      margin-top: 15px;
      color: #4F9A00;
    }
  }
}
