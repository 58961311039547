.email-profiles {
  margin-bottom: 30px;

  .table {
    &--column {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      color: #717171;

      &.mail-template-id {
        color: #000000;
        font-family: IBMPlexSansCondensed-Medium, sans-serif;
      }
    }
  }
}

.add-email-profile {
  .format {
    max-width: 160px;
  }

  .general-form {
    &--title {
      margin-bottom: 16px;
    }
    &--container {
      margin-bottom: 0;
    }
  }
}
