.sidebar {
  width: 220px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $secondary-content-bg;
  position: relative;
  transition: width .3s;
  padding-top: 60px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  &__logo-holder {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  &__collapsed {
    width: 56px;
    overflow: hidden;

    .ant-menu-submenu-arrow {
      visibility: hidden;
    }
  }

  &--collapse {
    position: absolute;
    top: 18px;
    right: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C2C2C2;
    color: #ffffff;
    cursor: pointer;

    &__collapsed {
      right: 18px;
    }
  }

  .sidebar--panel.MuiExpansionPanel-root {
    margin: 0;
    box-shadow: none;
    background-color: transparent;

    &:before {
      content: none;
    }
  }

  .sidebar--panel-summary.MuiExpansionPanelSummary-root {
    min-height: initial;
    padding: 0 15px;

    .MuiExpansionPanelSummary-content {
      margin: 10px 0;
      color: #000000;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
    }

    &.sidebar--link__highlighted .MuiExpansionPanelSummary-content {
      color: $primary-bg-color;
    }
  }

  &--panel-details.MuiExpansionPanelDetails-root {
    display: flex;
    flex-direction: column;
    padding: 8px 15px 15px;
    margin-left: 39px;
  }

  .sidebar--link {
    color: #000000;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &.selected {
      color: $primary-bg-color;
    }

    &__inner {
      margin-bottom: 0;
      padding-left: 40px;
    }
  }

  &--link-icon-holder {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &--inner-menu {
    &__counter {
      background-color: #FF0000;
      width: 18px;
      height: 18px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      color: #FFF;

      margin-left: 5px;
    }
  }
}
