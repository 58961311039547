.crm-table {
  .detail-column {
    font-size: 12px;
    line-height: 15px;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;

    &--business-name {
      color: #000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }

    &--email {
      color: #FF0000;
    }

    &--text {
      color: #717171;
    }

    &--date {
      padding: 5px 0 0;
    }
  }
}
