.dealer-edit-address {

  &--title-and-address {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 0 0 20px 0;
    }

    &--show-contacts-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &--show-contacts-checkbox-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 0 8px;
    }

    &--content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      &--fields {

        &--left-row {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          grid-gap: 16px;
          margin: 8px 30px 0 0;

          &--address-label {
            color: #717171;
            font-family: "IBMPlexSansCondensed-Regular", sans-serif;
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 4px 0;
          }

          #dealer-edit-address--title-and-address--address-text-area {
            min-width: 400px;
          }
        }

        &--right-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &--left-column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 30px 0 0;
          }
        }
      }
    }
  }

  &--contact-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 20px 0;
    }

    &--show-contacts-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &--show-contacts-checkbox-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 0 8px;
    }

    &--content-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      &--telephone-input,
      &--mobile-input {
        min-width: 255px;
        margin: 0 30px 0 0;
      }

      &--email-input {
        min-width: 255px;
        margin: 0 30px 0 0;
      }
    }
  }

  &--additional-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 20px 0;
    }

    &--content {

      &--additional-section-label {
        color: #717171;
        font-family: "IBMPlexSansCondensed-Regular", sans-serif;
        font-size: 14px;
        line-height: 18px;
        margin: 0 0 4px 0;
      }
    }
  }

  &--directions-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 20px 0;
    }

    &--show-directions-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &--show-directions-checkbox-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 0 8px;
    }
  }

  &--location-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--title {
      color: #000000;
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: 25px;
      margin: 32px 0 20px 0;
    }

    &--show-location-checkbox {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 20px 0;
    }

    &--show-location-checkbox-label {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 0 8px;
    }

    &--location .bitmap {
      min-height: 402px;
      min-width: 1111px;
    }
  }

  &--action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0 24px 0;

    &--save-button {
      margin: 0 8px 0 0;
    }

    .ant-btn {
      height: 32px;
      width: 160px;
    }
  }

  &--town-or-city-input,
  &--country-input,
  &--region-input {
    min-height: 32px;
    min-width: 255px;
  }

  &--town-or-city-input-label,
  &--country-input-label,
  &--region-input-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 4px 0;
  }
}

#dealer-edit-address--additional-text-area,
#dealer-edit-address--directions-text-area {
  min-height: 100px;
}

.leaflet-container {
  height: 400px;
  width: calc(100vw - 350px);
}

.sidebar__collapsed + .admin-content {
  .leaflet-container {
    width: calc(100vw - 186px);
  }

  .dealers-news .table {
    max-width: calc(100vw - 186px);
  }
}
