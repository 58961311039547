.single-dealer--images-tab {
  margin: 9px 0 164px 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .ant-row {
    width: 100%;
    margin: 0 0 32px -15px !important;
  }

  &--action-buttons {
    display: flex;
    flex-direction: row;
  }

  &--save-btn,
  &--cancel-btn {
    margin: 0 8px 0 0;
    width: 115px;
  }
}
