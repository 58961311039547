@import "display";
@import "text";
@import "space";

.w-100 {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.px-30 {
  padding: 0 30px;
}

.pt-0 {
  padding-top: 0;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-32 {
  padding-top: 32px;
}

.pr-0 {
  padding-right: 0;
}

.pr-16 {
  padding-right: 16px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-0 {
  padding-bottom: 0;
}

.pl-16 {
  padding-left: 16px;
}

.overflow-x-auto {
  overflow-x: auto;
}

.red-color {
  color: #FF0000;
}

.danger {
  color: var(--ant-primary-color);

  &:hover {
    color: var(--ant-primary-color-hover);
  }
}

.bg-success {
  background-color: #4F9A00;
}

.bg-warn {
  background-color: #f89407;
}

.bg-error {
  background-color: #FF0000;
}

.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.8;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}
