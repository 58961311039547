.tax-rates {
  .table {
    &--column {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;

      &.tax-code--column {
        color: #000000;
        font-family: IBMPlexSansCondensed-Medium, sans-serif;
      }
    }
  }
}

.add-tax-rate-item {
  box-sizing: border-box;
  width: calc(100% - 130px);
  margin: 0 0 0 50px;

  &--title {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }
  &--button-holder {
    display: flex;
    margin-top: 32px;
  }

  .checkbox-holder {
    padding: 16px 0;
  }

  .labeled-input,
  .general-form--button-holder {
    max-width: 350px;
  }

  .general-form--button-holder {
    button {
      width: 50%;
    }
  }

  .error {
    color: #FF0000;
    font-size: 12px;
  }
}
