$primary-content-bg: #F7F7F9;
$secondary-content-bg: #FFFFFF;

$primary-bg-color: #FF0000;
$secondary-bg-color: #004F57;

$primary-fg-color: #000000;
$secondary-fg-color: #717171;
$highlight-fg-color: #FF0000;

:root {
  --lant-gray-10: #ffffff;
  --lant-gray-75: #717171;
  --lant-gray-130: #000000;

  --lant-success: #52C41A;
  --lant-info: #1890FF;
  --lant-warning: #FAAD14;
  --lant-danger: #FF0000;
  //--lant-danger: #FF4D4F;
}
