.dealers-news {
  margin-bottom: 100px;

  p, span {
    margin: 0;
  }

  .table {
    max-width: calc(100vw - 350px);
    margin: 24px 0 0 0;

    &--column {
      padding: 8px;
    }
  }

  &--title-column {
    color: #000000;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  &--article-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed", sans-serif;
    font-size: 12px;
    max-height: 50px;

    overflow: hidden;
  }

  &--date-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }

  .ant-select-arrow {
    margin: -5px 0 0 0;
  }

  &--radio-group {
    margin-top: 20px;
  }

  &__header-controls {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 16px;
  }

  &--actions-column {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;

    grid-gap: 16px;
    cursor: pointer;
  }

  &--action-icon {
    width: 18px;
    height: 18px;

    &:hover {
      color: #FF0000;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--tabs {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      text-shadow: none;
    }
  }
}
