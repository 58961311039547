.multiple-videos {
  &--uploader {
    position: relative;
    width: 256px;
    height: 144px;

    .ant-upload.ant-upload-drag {
      width: 256px;
      height: 82px;
      background-color: #F7F7F9;

      .anticon-plus {
        color: inherit;
        font-size: 14px;
      }
    }
  }

  &--preview {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: auto;
    padding: 20px;
    border: 1px dashed rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }

  &--divider {
    align-items: center;
    color: rgba(0, 0, 0, .5);
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin: 4px 0;
    text-align: center;
    text-transform: uppercase;

    &:before,
    &:after {
      background-color: rgba(0, 0, 0, .2);
      content: " ";
      height: 1px;
      position: absolute;
      width: 40%;
    }

    &:before {
      right: 0;
    }

    &:after {
      left: 0;
    }
  }

  &--url {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
}

.la-video__preview {
  position: relative;

  iframe {
    display: block;
  }
}

.la-video__remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  display: flex;
  background-color: #F7F7F9;
  padding: 4px;
  border-radius: 50%;
  font-size: 12px;
  cursor: pointer;
}
