.component {
  padding: 0 80px 0 50px;

  &--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    &.with-tab-nav {
      margin-top: 20px;
    }
  }

  &--header-section {
    display: flex;
    align-items: center;
  }
}

.with-cursor {
  cursor: pointer
}

.button {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $secondary-bg-color;
  border-radius: 4px;
  color: #ffffff;
  width: auto;
  height: 32px;
  justify-content: space-around;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0 8px;
}

.general-form {
  .editor-type-switcher {
    margin: 25px 0 5px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &__align-start {
      justify-content: flex-start;
    }
  }

  &--column {
    width: 50%;
    margin-right: 30px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
    margin-bottom: 0;
  }

  &--control {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--control-icon {
    margin-right: 8px;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &--checkbox-holder {
    padding: 16px 0;
  }

  &--checkbox-holder-artisan {
    justify-content: flex-start;
  }

  &--input-holder {
    position: relative;
  }

  &--url-input-image {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &--button-holder {
    display: flex;
    margin: 20px 0;

    .ant-btn {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &--plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-bg-color;
    color: #ffffff;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-left: 8px;
    flex-shrink: 0;
    cursor: pointer;
  }

  &--container {
    margin-bottom: 32px;
    max-width: 540px;
  }
}

.react-images__view-image {
  max-height: calc(100vh - 100px) !important;
}

.table-image {
  margin-right: 8px !important;
}

.table-text {
  color: #000000;
  font-size: 12px;
  font-family: "IBMPlexSansCondensed-Medium", sans-serif;
}

.table-sm-text {
  color: $secondary-fg-color;
  font-size: 12px;
  font-family: "IBMPlexSansCondensed-Regular", sans-serif;
}

.ql-align-right {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 auto;
}

.ql-align-center {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.ql-size-small {
  font-size: 12px;
}

.ql-size-large {
  font-size: 18px;
  font-weight: 600;
}

.ql-size-huge {
  font-size: 21px;
  font-weight: 700;
}

.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}

.item-status-pop-btn {
  margin-top: -4px;
}

.lant-icon {
  color: var(--lant-gray-75);
  font-size: 16px;
  line-height: 1;

  &.danger {
    color: var(--lant-danger);
  }
}
