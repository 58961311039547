html {
  --ant-primary-color: #ff0000;
  --ant-primary-color-hover: #f5222d;
  --ant-primary-color-active: #ff0000;
  --ant-primary-color-outline: rgba(255, 0, 0, 0.2);
  --ant-primary-1: #ffeae6;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #ff887a;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #ff3029;
  --ant-primary-6: #ff0000;
  --ant-primary-7: #ff0000;
  --ant-link-color: #ff0000;
}

//@menu-item-active-bg: transparent;
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

//@menu-item-active-border-width: 0;
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-width: 0;
}

//@menu-item-vertical-margin: 0;
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

//@menu-item-boundary-margin: 0;
.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}

//@menu-bg: transparent;
.ant-menu {
  background: transparent;
}

//@breadcrumb-link-color: #FF0000;
.ant-breadcrumb a {
  color: #FF0000;
}

//@avatar-bg: #FF0000;
//@avatar-color: #FFFFFF;
//@avatar-border-radius: 2px;
.ant-avatar {
  min-width: 32px;
}

.ant-avatar:not(.ant-avatar-image) {
  background-color: #FF0000;
}

.ant-menu-inline {
  border-right: none;

  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-right: 0;
    padding-left: 16px !important;
  }

  .ant-menu-sub .ant-menu-item {
    height: 30px;
    margin: 0;
  }
}

.ant-btn {
  font-family: "IBMPlexSansCondensed-Medium", sans-serif;
}

.ant-btn-icon {
  margin-right: 10px;
  vertical-align: sub;
}

.ant-menu-horizontal {
  display: flex;

  .ant-menu-item {
    display: flex;
    align-items: center;
  }
}

.ant-select.labeled-input--input {
  padding: 0;
}

.ant-select-dropdown .thin-label {
  margin-left: 2px;
  font-family: "IBMPlexSansCondensed-Regular", sans-serif;
}

.ant-select-selection {
  border: none;
}

.ant-input,
.ant-select-selection,
.ant-calendar-picker-input,
.search-input--input {
  &:hover,
  &:focus,
  &:focus-visible {
    border-color: initial !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 30px;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: fit-content;

  .ant-checkbox {
    top: 0;
  }
}

.ant-menu-horizontal {
  line-height: 36px;

  li:not(:last-child) {
    margin: 0 20px 0 0;
    padding: 0;
  }
}

.ant-upload-picture-card-wrapper {
  height: 100%;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: #F7F7F9;
  margin-bottom: 0;
  margin-right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: rgba(0, 0, 0, .3);

  .ant-upload[role="button"] {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-popover-title,
.ant-popover-inner-content {
  display: flex;
  justify-content: center;
}

.ant-popover-inner-content {
  flex-direction: column;
}

.ant-popover-buttons {
  margin-top: 4px;
  margin-bottom: 0 !important;
}
