.add-dealer-speciality {
  box-sizing: border-box;

  .error {
    color: $primary-bg-color;
    font-size: 12px;
  }

  &--row {
    display: flex;
    justify-content: space-between;

    &.checkbox-holder {
      padding: 16px 0;
    }
  }

  &--title {
    color: $primary-fg-color;
    font-family: "IBMPlexSansCondensed-Bold", sans-serif;
    font-size: 20px;
  }

  &--description-field-label {
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    color: #717171;
    margin: 0 0 4px 0;
  }

  #add-dealer-speciality--description-field {
    height: 64px;
    width: 350px;
  }

  .ant-input {
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    color: $primary-fg-color;
    padding-right: 35px;
  }

  .labeled-input {
    max-width: 350px;

    &--label {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    }

    input {
      max-width: 350px;
    }
  }

  &--button-holder {
    display: flex;
    margin: 32px 0 40px 0;

    .del-btn-holder {
      margin-left: 15px;

      img {
        vertical-align: sub;
        padding: 0 10px 0 0;
      }

      .btn-secondary {
        color: rgba(0 , 0, 0, 0.5);
        min-width: 116px;
      }
    }
  }

  &--save-and-close-btn, &--delete-btn {
    width: 160px;
  }

  &--title-field, &--name-field {
    margin: 8px 0 0 0;
  }
}
