.seo-cat {
  .table {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 15px;
  }
  &--row {
    position: relative;
    flex-wrap: wrap;
  }

  &--select {
    min-width: 162px;
  }

  &--auto-button {
    display: flex !important;
    align-items: center;
  }

  &--auto-button-icon {
    margin-right: 8px;
  }

  &--status-block {
    width: 100px;
    display: flex;
    flex-direction: column;
    padding-right: 12px;
  }

  &--status {
    text-align: center;
    margin-bottom: 1px;
    background-color: #C2C2C2;
    color: #FFFFFF;
    font-size: 12px;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    cursor: pointer;

    &:nth-of-type(1) { border-radius: 4px 4px 0 0 }
    &:last-of-type { border-radius: 0 0 4px 4px }
  }

  &--title-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 8px;
  }

  &--title {
    font-size: 20px;
    font-weight: bold;
    font-family: "IBMPlexSans-Bold", sans-serif;
    margin: 0;
    line-height: 25px;
  }

  &--sub-title {
    color: #717171;
    font-size: 12px;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    margin: 4px 0 0;
    line-height: 15px;
  }

  &--link {
    color: #FF0000;
    font-size: 12px;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    line-height: 15px;
    margin: 8px 0 4px;
  }

  &--column-number {
    display: flex;
    align-items: flex-start;
    padding-top: 21px;

    &__red {
      color: #FF0000
    }

    &__green {
      color: #4F9A00;
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 16px;
    padding-bottom: 8px;
    height: auto;
  }

  &--checkbox {
    margin-top: 12px !important;
    margin-left: 0 !important;
  }

  &--input {
    margin: 8px 0 0 0;
  }

  &--image-holder {
    display: flex;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 8px;
    background-color: #FFF;
  }

  &--image {
    width: 33%;
    object-fit: contain;
    border: 1px dashed #cccccc;
  }

  &--banner {
    width: 65%;
    object-fit: contain;
    border: 1px dashed #cccccc;
  }

  &--image-picker {
    margin: 0 40px 0 20px;

    @media screen and (max-width: 1350px) {
      max-width: 150px;
    }
  }

  &--banner-picker {
    margin: 0 20px 0 0;

    @media screen and (max-width: 1050px) {
      width: 200px;
    }

    @media screen and (max-width: 800px) {
      width: 150px;
    }
  }

  &__filer-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__search-box {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  &__search-label {
    margin-right: 5px;
    display: flex;
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    flex-shrink: 0;
  }

  &--description-options {
    flex: 0 0 100%;
    padding: 30px 12px;
  }
}
