#order-header-tab, #order-history-tab {
  display: flex;
  flex-direction: row;
  padding: 3px;

  p {
    margin: 0 0 0 8px;
  }
}

.ant-tabs-tab-active, .ant-tabs-tab {
  padding: 12px 0 12px 0 !important;
}

.ant-tabs-bar {
  margin: 0 !important;
}

.order-header {
  display: flex;
  flex-direction: row;
  margin: 24px 0 0 0;

  &--left-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 30px 0 0;
  }

  &--right-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .header-tab-column-title {
    color: #000000;
    font-family: "IBM Plex Sans", serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }

  .header-tab-column-label {
    height: 15px;
    min-width: 115px;
    color: #717171;
    font-family: "IBM Plex Sans Condensed", serif;
    font-size: 12px;
    line-height: 15px;
    margin: 0 60px 8px 0;
  }

  &--header-column-wrapper {
    .header-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .header-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .cart-id-field-value, .date-field-value,
    .order-number-field-value, .attempts-field-value,
    .order-ref-field-value, .invoice-ref-field-value,
    .invoice-date-field-value {
      width: auto;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
    }

    .order-status-field-value {
      display: flex;
      flex-direction: row;
      width: auto;

      &--paid {
        height: 16px;
        width: 31px;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        background-color: #004fe1;
        border-radius: 4px;
        margin: 0 4px 4px 0;
      }

      &--refunded {
        height: 16px;
        width: 58px;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        background-color: #F89305;
        border-radius: 4px;
        margin: 0 4px 4px 0;
      }

      &--bounced {
        height: 16px;
        width: 53px;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        background-color: #F70501;
        border-radius: 4px;
        margin: 0 4px 4px 0;
      }

      &--completed {
        height: 16px;
        width: 64px;
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        background-color: #4F9A00;
        border-radius: 4px;
        margin: 0 4px 4px 0;
      }
    }

  }

  &--billing-and-shipping-wrapper {
    margin: 24px 0 0 0;

    .billing-and-shipping-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .billing-and-shipping-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .name-field-value, .company-field-value,
    .address-field-value, .order-number-field-value {
      width: auto;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
    }

    .email-field-value {
      color: #FF0000;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--order-history-wrapper {
    margin: 24px 0 0 0;

    .order-history-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .order-history-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .history-description-field-value {
      width: auto;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
    }

    .history-description-empty-field {
      width: auto;
      color: #717171;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--payment-wrapper {
    .payment-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .payment-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .payment-method-field-value,
    .payment-date-field-value, .payment-ref-field-value,
    .customer-ref-field-value {
      width: auto;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
    }

    .payment-status-completed {
      height: 16px;
      width: 65px;
      color: #FFFFFF;
      font-size: 12px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 4px;
      margin: 0 4px 4px 0;
    }

    .payment-status-uncompleted {
      height: 16px;
      width: 75px;
      color: #FFFFFF;
      font-size: 12px;
      text-align: center;
      background-color: #F70501;
      border-radius: 4px;
    }
  }

  &--totals-wrapper {
    margin: 24px 0 0 0;

    .totals-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .totals-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .totals-currency-field-value, .totals-method-field-value,
    .totals-date-field-value, .totals-ref-field-value {
      width: auto;
      color: #000000;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--customer-notes-wrapper {
    margin: 24px 0 0 0;

    .customer-notes-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .customer-notes-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .customer-notes-field-value {
      color: #717171;
      font-family: "IBM Plex Sans Condensed", serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  &--order-notes-wrapper {
    margin: 24px 0 0 0;

    .order-notes-column-info {
      min-width: 540px;
      border-radius: 4px;
      background-color: #FFFFFF;
      margin: 8px 0 0 0;
      padding: 16px;
    }

    .order-notes-column-info-field {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .order-notes-textarea {
      color: #717171;
      font-family: "IBM Plex Sans Condensed", serif;
      font-size: 12px;
      line-height: 15px;
      border: none !important;
      width: 508px;
    }

    svg {
      margin: 0 0 0 10px;
    }
  }
}
