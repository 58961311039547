.invoice-list {
  margin-bottom: 30px;

  .component {
    &--header {
      &-section {
        flex: 1 1;
        max-width: 800px;

        .mr-16p {
          margin-right: 16px;
        }

        .w-100 {
          width: 100%;
        }

        .DateRangePicker {
          display: none;
        }
      }
    }
  }

  .table {
    &--row {
      font-size: 12px;
    }
    &--column {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;

      &.dealer-column,
      &.total-column {
        color: #000000;
        font-family: IBMPlexSansCondensed-Medium, sans-serif;
      }

      &.actions-column {
        justify-content: flex-end;
      }
    }

    .groups--table-icon {
      margin-right: 8px;
    }
  }
}
