.website-builder {
  &--container {
    &--header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      &--left-side {
        width: 50%;
        display: flex;

        .requests-select {
          width: 50%;
          margin: 0 20px 0 0;

          .labeled-input--input {
            border: none;
          }

          @media screen and (max-width: 920px) {
            margin-right: 35px;
          }
        }
      }

      &--right-side {
        .search-input--input {
          padding: 0 5px;
        }
      }
    }

    &--content {
      &--table-row {
        padding: 2px;

        .table--column {
          padding: 10px;

          .ant-switch-checked {
            background-color: #1ED303;
          }
        }

        .table--column:not(:first-child) {
          display: flex;
          align-items: center;
        }

        .table--column:first-child {
          border-radius: 4px 0 0 4px;
        }

        .table--column:last-child {
          border-radius: 0 4px 4px 0;
        }

        .publish-req {
          &--status-block {
            width: 120px;
            display: flex;
            flex-direction: column;
            padding-right: 12px;
            flex-shrink: 0;

            &--status {
              text-align: center;
              margin-bottom: 1px;
              background-color: #C2C2C2;
              color: #FFFFFF;
              font-size: 12px;
              font-family: "IBMPlexSansCondensed-Medium", sans-serif;
              cursor: pointer;

              &:nth-of-type(1) {
                border-radius: 4px 4px 0 0;
              }

              &:last-of-type {
                border-radius: 0 0 4px 4px;
              }

              &.done {
                background-color: #4F9A00;
              }

              &.pending {
                background-color: #F89407;
              }

              &.declined {
                background-color: #FF0000;
              }

              &.cancelled {
                background-color: rgb(156, 39, 176);
              }

              &_disabled {
                cursor: not-allowed;
              }
            }
          }
        }

        &--copy-icon {
          font-size: 22px;
          cursor: pointer;

          &_small {
            line-height: 18px;
            font-size: 18px;
            margin-left: 15px;

            svg {
              color: #717171
            }
          }

          &_disabled {
            opacity: .5;
            cursor: not-allowed !important;
          }

          &_delete {
            margin-left: 8px;
            opacity: .5;
            color: #FF0000 !important;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .table {
        @media screen and (max-width: 1300px) {
          min-width: 1000px;
        }
      }
    }
  }
}
