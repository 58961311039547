.status-panel {
  &.disabled {
    cursor: not-allowed;
  }

  &--item {
    background-color: #C2C2C2;
    color: #FFFFFF;
    font-size: 12px;
    margin-bottom: 1px;
    padding: 0 8.83px 1px;
    text-align: center;
    text-transform: capitalize;

    &.larger {
      padding: 10px;
      min-width: 80px;
    }

    &.shorter {
      height: 20px;
      padding: 2px;
      min-width: 70px;
    }

    &:only-child {
      padding-left: 4px;
      padding-right: 4px;
    }

    &:first-of-type {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-of-type {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.inactive,
    &.declined {
      background-color: #FF0000;
    }

    &.sold,
    &.pending,
    &.submitted,
    &.html {
      background-color: rgb(235, 152, 56);
    }

    &.reserved {
      background-color: rgb(33, 150, 243);
    }

    &.approved,
    &.available,
    &.active {
      background-color: rgb(26, 179, 148);
    }

    &.u {
      background-color: #717171;
    }

    &.d,
    &.text {
      background-color: #004F57;
    }

    &.clickable {
      cursor: pointer;
    }

    &.no-clickable {
      cursor: default;
    }
  }

  &.horizontal {
    display: flex;
    flex-wrap: nowrap;

    .status-panel--item,
    .status-panel--item {
      min-width: auto;
      height: 16px;
      padding: 0 5px;
      margin: 0 1px 0 0;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;

      &:first-of-type {
        border-top-right-radius: 0;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0;
      }
    }
  }
}
