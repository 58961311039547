.table-tag {
  border-radius: 4px;
  background-color: #004F57;
  color: #FFFFFF;
  font-size: 12px;
  padding: 1px 5px;
  display: flex;
  align-items: center;
  margin: 1px;

  &.no-category {
    background-color: #C2C2C2;
  }
}
