.dealer-list {
  .table {
    &--column {
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;

      &.dealer-column {
        color: #000000;
        font-family: IBMPlexSansCondensed-Medium, sans-serif;
      }
    }
  }
}
