.image-uploader {

  &--top-text,
  &--bottom-text {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &--upload-image {
    height: 256px;
    width: 100%;
    object-fit: contain;
  }

  &--upload-remove {
    position: absolute;
    color: #fff;
    background-color: #717171;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  &--upload-hint {
    color: rgba(0,0,0,0.5);
    font-size: 14px;
    text-align: center;
    padding: 48px 0 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
    }
  }

  &--upload {
    width: 100%;
    height: 256px !important;
    border-radius: 4px !important;
    position: relative;
    background-color: #F7F7F7;
    margin: 3.5px 0 !important;

    .ant-upload.ant-upload-drag, .ant-upload {
      padding: 0 !important;
    }
    .ant-upload-btn {
      .ant-upload-drag-container {
        display: flex !important;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
