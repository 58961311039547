.dealer-address-tab {

  &--add-button {
    margin: 24px 0;
  }

  &--table {
    width: 100%;
    margin: 0 0 0 -10px;

    &__shrink {
      flex-grow: 0;
      flex-basis: calc(66.6% - 15px);
    }
  }

  &--address-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &--contact-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &--email-row, &--phone-row, &--mobile-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
      margin: 2px 0 0 0;
    }

    &--email-label, &--phone-label, &--mobile-label {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      min-width: 35px;
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      margin: 0 16px 0 0;
    }

    &--email-value {
      color: #FF0000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }

    &--phone-value, &--mobile-value {
      color: #000000;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    }
  }
}
