.cover-image {
  width: 100%;
}

.text-logo {
  &__input {
    .input-field--input-label {
      font-size: 12px;
    }

    .input-field {
      &:hover {
        border-color: #7F7F7F !important;
      }

      .ant-input {
        font-size: 14px;
      }
    }
  }

  &__controls {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      justify-content: flex-start;
    }
  }

  &__control-label {
    margin-bottom: 2px;
    font-size: 10px;
    color: #666666;
  }

  &__color-selector-container {
    position: relative;
    display: inline-block;
  }

  &__color-selector {
    width: 42px;
    height: 34px;
    border-radius: 2px;
    cursor: pointer;
    border: 1px solid #F1F1F1;

    &_disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  &__color-picker {
    position: absolute;
    right: 0;
    top: 55px;
    z-index: 2;

    @media screen and (max-width: 576px) {
      left: 2px;
      top: 55px;
    }
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 125px;
    border: 2px dashed #979797;
    border-radius: 4px;
    margin-bottom: 24px;
    text-align: center;
  }

  &__select {
    &.ant-select {
      margin-right: 12px;
      border: 1px solid #F1F1F1;
      border-radius: 2px;
      margin-bottom: 10px;
    }

    .ant-select-selector {
      font-size: 12px;
      background-color: #F7F7F7 !important;
    }

    &--font {
      min-width: 130px;
    }

    &--weight {
      min-width: 100px;
    }

    &--size {
      min-width: 55px;
    }
  }

  &__option {
    &--allura {
      font-family: "Allura", sans-serif;
      font-size: 18px !important;
    }

    &--dancing-script {
      font-family: "Dancing Script", sans-serif;
      font-size: 16px !important;
    }

    &--euphoria-script {
      font-family: "Euphoria Script", sans-serif;
      font-size: 18px !important;
    }

    &--lato {
      font-family: "Lato", sans-serif;
    }

    &--libre-baskerville {
      font-family: "Libre Baskerville", sans-serif;
    }

    &--lobster {
      font-family: "Lobster", sans-serif;
    }

    &--lovers-quarrel {
      font-family: "Lovers Quarrel", sans-serif;
      font-size: 24px !important;
    }

    &--montserrat {
      font-family: "Montserrat", sans-serif;
    }

    &--oswald {
      font-family: "Oswald", sans-serif;
    }

    &--playball {
      font-family: "Playball", sans-serif;
    }

    &--roboto {
      font-family: "Roboto", sans-serif;
    }

    &--russo-one {
      font-family: "Russo One", sans-serif;
    }

    &--sail {
      font-family: "Sail", sans-serif;
    }

    &--source-sans-pro {
      font-family: "Source Sans Pro", sans-serif;
    }

    &--kaushan-script {
      font-family: "Kaushan Script", sans-serif;
    }

    &--arizonia {
      font-family: "Arizonia", sans-serif;
    }

    &--monsieur-la-doulaise {
      font-family: "Monsieur La Doulaise", sans-serif;
    }

    &--big_shoulders_stencil_text {
      font-family: "Big Shoulders Stencil Text", sans-serif;
    }

    &--monoton {
      font-family: "Monoton", sans-serif;
    }

    &--buda {
      font-family: "Buda", sans-serif;
    }

    &--vast_shadow {
      font-family: "Vast Shadow", sans-serif;
    }

    &--forum {
      font-family: "Forum", sans-serif;
    }

    &--tangerine {
      font-family: "Tangerine", sans-serif;
    }

    &--arapey {
      font-family: "Arapey", sans-serif;
    }

    &--special_elite {
      font-family: "Special Elite", sans-serif;
    }

    &--josefin_slab {
      font-family: "Josefin Slab", sans-serif;
    }

    &--spartan {
      font-family: "Spartan", sans-serif;
    }

    &--eb_garamond {
      font-family: "EB Garamond", sans-serif;
    }

    &--nanum_gothic {
      font-family: "Nanum Gothic", sans-serif;
    }

    &--caramel {
      font-family: "Caramel", sans-serif;
    }

    &--cinzel_decorative {
      font-family: "Cinzel Decorative", sans-serif;
    }

    &--playfair_display_sc {
      font-family: "Playfair Display SC", sans-serif;
    }

    &--chakra_petch {
      font-family: "Chakra Petch", sans-serif;
    }

    &--cinzel {
      font-family: "Cinzel", sans-serif;
    }

    &--crimson-text {
      font-family: "Crimson Text", sans-serif;
    }

    &--windsong {
      font-family: "Windsong", sans-serif;
    }

    &--bodoni_classico {
      font-family: "Bodoni Classico", sans-serif;
    }

    &--classic-roman {
      font-family: "Classic Roman", sans-serif;
    }

    &--explorer {
      font-family: "Explorer", sans-serif;
    }
  }
}
