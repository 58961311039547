.article-preview {
	box-sizing: border-box;
	min-height: 724px;
	width: 848px;
	border: 1px solid #E5E5E5;
	border-radius: 4px;
  background-color: #FFFFFF;
  margin: 24px 0;
  padding: 28px 15px 0 15px;

  &--posted-date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    margin: 8px 0 0 0;
  }

  &--title {
    color: #004F57;
    font-family: "Source Sans Pro", serif;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
  }
}

#article-preview--content-row {
  color: rgba(0,0,0,0.85);
  font-family: "Source Sans Pro", serif;
  font-size: 18px;
  line-height: 26px;
  max-width: 840px;
  word-wrap: break-word;
  margin: 32px 0 0 0;

  img {
    max-width: 815px;
  }

  a {
    color: #EC521E;
    text-decoration: underline;
  }
}
