.trade-associations {
  .single-association-container {
    &--name {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700;
    }

    &--form {
      width: 100%;
      max-width: 550px;

      &--btns-container {
        display: flex;

        .delete-btn {
          margin-left: 10px;
        }
      }

      &--uploader {
        margin: 25px 0;

        &--hint {
          font-family: "IBMPlexSansCondensed-Regular", sans-serif;
          color: #717171;
          font-size: 14px;

          .red-color {
            color: #FF0000;
          }
        }
      }

      .labeled-input {
        width: 80%;
        margin-bottom: 10px;
      }
    }
  }

  &--spinner-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &--header {
    display: flex;
    justify-content: space-between;
  }

  &--content {
    margin-top: 35px;
  }

  .associations-table {
    .ant-table-row {
      cursor: move;
    }

    .ant-table-tbody {
      .ant-table-cell {
        &:last-child {
          text-align: right;
        }
      }
    }

    .ant-table-thead {
      // rewriting default table header border style
      tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        width: 0;
      }

      .ant-table-cell {
        background-color: #F7F7F9;

        &:last-child {
          text-align: right;
        }
      }
    }

    &--logo {
      width: 32px;
      height: 32px;
    }

    &--name {
      margin-left: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }

    &--link-icon {
      cursor: pointer;
      margin-left: 5px;
    }

    &--link {
      color: #717171;
    }

    &--actions-container {
      &--icon {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
