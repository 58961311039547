.groups {
  &--title-column {
    padding-left: 4px;
  }

  &--title-image {
    margin-right: 8px;
  }

  &--table-icon {
    margin-right: 16px;
    cursor: pointer;
    font-size: 16px;
  }

  &--lines {
    background-color: $primary-content-bg;
    width: 35px;
    height: calc(100% + 8px);
    margin: -4px 4px -4px -4px;
    position: relative;

    &:before,
    &:after {
      content: "";
      display: block;
      border-color: #E5E5E5;
      border-style: dashed;
      border-width: 0;
      position: absolute;
    }

    &:before {
      border-right-width: 2px;
      left: 11px;
      height: 100%;
    }

    &:after {
      width: 22px;
      border-top-width: 2px;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__last:before {
      height: 50%;
    }

    &__no-horizontal:after {
      content: none;
    }

    &__no-lines:before,
    &__no-lines:after {
      content: none;
    }
  }

  .general-form {
    box-sizing: border-box;
    background-color: $secondary-content-bg;
    padding: 15px;
    transition: width .3s, background-color .3s, padding .3s;
  }
}

.add-catalog-entity {
  &--select {
    width: 100%;

    .ant-select-selection-selected-value {
      color: $primary-fg-color;
    }

    &.artisan-tag-selector {
      .ant-select-selection-overflow-item {
        &:first-child {
          .ant-select-selection-item-content {
            color: #FF0000;
          }
        }
      }
    }
  }

  &--image-picker {
    width: 100%;
    max-width: 256px;
    margin-right: 30px;
  }

  .input-with-prefix {

    .ant-input {
      padding-left: 5px;
    }

    .ant-input-group-addon {
      padding: 2px 5px 0 5px;
    }
  }
}

.category-image-uploader-error-text {
  color: $primary-bg-color;
  font-size: 12px;
}
