.payments {
  .general-form {
    &--container {
      max-width: 540px;
    }
    &--title {
      margin-bottom: 16px;
    }
  }
}
