.artisans {
  &--row-block {
    padding: 4px;

    .ant-avatar {
      margin-right: 8px;
    }

    &__name {
      border-radius: 4px 0 0 4px;
    }

    &__actions {
      border-radius: 0 4px 4px 0;
    }
  }

  &--remove-linked-icon {
    cursor: pointer;
    opacity: 0.5;
  }

  &__tags-navigation {
    margin-bottom: 20px;
  }

  &__created-column {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 15px;
  }
}
