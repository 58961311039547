.dealer-status-tab {
  display: flex;
  flex-direction: row;
  margin: 36px 0 0 0;

  &--left-column {
    margin: 0 30px 0 0;
  }

  &--left-column, &--right-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .stats-section {
    display: flex;
    flex-direction: column;
    min-width: 540px;

    &--header {
      display: flex;
      justify-content: space-between;
    }

    &--title {
      font-family: "IBMPlexSans-Bold", sans-serif;
      font-size: 20px;
      color: $primary-fg-color;
      margin: 0 0 16px 0;
    }

    &--content {
      display: flex;
      flex-wrap: wrap;

      .flex {
        display: flex;
      }

      &__background {
        background-color: $secondary-content-bg;
      }
    }

    .ant-radio-wrapper span {
      color: #717171;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .confirmation-double-toggle-button,
  .login-double-toggle-button,
  .index-double-toggle-button,
  .merchant-feed-double-toggle-button,
  .dealer-page-when-inactive-toggle-button,
  .double-toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > * {
      cursor: pointer;
    }

    &--left-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--left-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #FF0000;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }
  }

  .api-double-toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &--left-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--left-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 16px;
      text-align: center;
      background-color: #F89407;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }
  }

  .item-status-triple-toggle-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > * {
      cursor: pointer;
    }

    &--left-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--left-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #4F9A00;
      border-radius: 3px 0 0 3px;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--middle-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #C2C2C2;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--middle-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #F89407;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #C2C2C2;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }

    &--right-side-active {
      height: 16px;
      color: #FFFFFF;
      font-family: "IBMPlexSansCondensed-Medium", sans-serif;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      background-color: #FF0000;
      border-radius: 0 3px 3px 0;
      padding: 0 3px;
      margin: 0 2px 0 0;
    }
  }
}

.active-invoices-section {
  margin: 32px 0 0 0;

  &--title {
    color: #000000;
    font-family: "IBMPlexSans-Bold", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
  }

  &--content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 540px;
    height: 310px;
    border-radius: 4px;
    margin: 8px 0 0 0;
  }

  &--left-column {
    margin: 0 30px 0 0;
  }

  &--left-column, &--right-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
}

.active-invoice-block {
  min-height: 80px;
  min-width: 255px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  padding: 16px;
  margin: 0 0 16px 0;

  &--value {
    color: #004F57;
    font-family: "IBMPlexSansCondensed-Medium", sans-serif;
    font-size: 28px;
    line-height: 37px;
  }

  &--text {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 12px;
    line-height: 16px;
  }
}

#dealer-since-date {
  color: #000000;
  font-family: "IBMPlexSansCondensed-Medium", sans-serif;
  font-size: 12px;
  line-height: 15px;
}
