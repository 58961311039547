.image-upload {
  width: 100%;

  &--uploader {
    width: 100%;
    margin: 4px 0;
  }

  &--top-text {
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    color: $secondary-fg-color;
    font-size: 14px;
    line-height: 18px;
  }

  &--bottom-text {
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    color: $secondary-fg-color;
    font-size: 14px;
    line-height: 18px;
  }

  &--icon {
    &.anticon {
      display: flex;
      align-items: center;
    }

    svg {
      width: 45px;
      height: 38.33px;
      opacity: 0.3;
    }
  }

  &--image {
    max-width: 100%;
    max-height: 100%;
  }
}
