.users {
  margin-bottom: 30px;

  &--content {
    .actions-column {
      margin-right: 16px;
      justify-content: flex-end;
    }
  }

  .component--header-section {
    &.left {
      max-width: 540px;
      flex: 1 1;

      .DateRangePicker {
        display: none;
      }
    }

    .mr-16p {
      margin-right: 16px;
    }
  }

  .table {
    &--column {
      font-size: 12px;
      font-family: "IBMPlexSansCondensed-Regular", sans-serif;
      color: #717171;

      &.column {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      &.details {
        .dealer-name {
          margin-right: 4px;
        }
      }

      &.type {
        .groups--table-icon {
          margin-left: 8px;
        }
      }

      &.email-address {
        & > div:first-child {
          margin-bottom: 3px;
        }
      }

      &.history {
        & > div:not(:last-child) {
          margin-bottom: 2px;
        }

        .left {
          min-width: 100px;
        }
      }

      .status-panel {
        margin-right: 4px;
      }
      .bold {
        color: #000000;
        font-family: 'IBMPlexSansCondensed-Medium', sans-serif;
      }
      .red {
        color: $primary-bg-color;
        font-family: 'IBMPlexSansCondensed-Medium', sans-serif;
      }
      .favorites {
        .same-width {
          display: inline-block;
          min-width: 88px;
        }
      }
    }
  }

  &--type-column {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &--user-role-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
    }

    &--user-status-row {
      &--active {
        height: 16px;
        width: 30px;
        text-align: center;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        background-color: #4F9A00;
        border-radius: 3px;
        padding: 0 3px;
        margin: 0 0 2px 0;
      }

      &--pending {
        height: 16px;
        width: 50px;
        text-align: center;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        background-color: #F89407;
        border-radius: 3px;
        padding: 0 3px;
        margin: 0 0 2px 0;
      }

      &--inactive {
        height: 16px;
        width: 50px;
        text-align: center;
        color: #FFFFFF;
        font-family: "IBMPlexSansCondensed-Medium", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 16px;
        background-color: #C2C2C2;
        border-radius: 3px;
        padding: 0 3px;
        margin: 0 0 2px 0;
      }
    }
  }

  .actions-column-resent-btn {
    margin-right: 16px;

    &__icon {
      width: 18px;
      height: 18px;
      display: block;

      svg {
        width: 100%;
        height: 100%;
      }
    }
   }
}

.ant-popover-placement-leftBottom {
  .ant-popover-title {
    justify-content: flex-start;
    border-bottom: none !important;
    background-color: white !important;
  }

  .ant-popover-inner-content {
    justify-content: flex-start;
  }

  .updgrade-user-role,
  .updgrade-dealer-role {
    img {
      margin: -3px 14px 0 0;
    }
  }

  .updgrade-trader-role {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
