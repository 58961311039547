.labeled-input {
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &--input {

    &--label-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      &--image {
        margin: 8px 8px 0 0;
      }
    }
  }

  .ant-input {
    &:hover {
      border-color: #000000 !important;
    }
  }

  &__horizontal {
    flex-direction: row;
    align-items: center;

    .labeled-input--label {
      margin-bottom: 0;
      margin-right: 5px;

      &:after {
        content: ":";
        display: inline-block;
        margin-left: 3px;
        color: inherit;
      }
    }
  }

  &__readonly {
    .labeled-input--input {
      background-color: $primary-content-bg !important;
    }
  }

  &__error {
    .labeled-input--input {
      border-color: red;
    }
  }

  &--label {
    display: flex;
    color: $secondary-fg-color;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    flex-shrink: 0;

    &__required:after {
      content: "*";
      display: inline-block;
      color: red;
    }
  }

  &--input {
    width: 100%;
    box-sizing: border-box;
    min-height: 33px;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: $secondary-content-bg !important;
    padding: 4px 11px;
    font-size: 14px;
    color: $primary-fg-color;

    &:focus,
    &:hover,
    &:active {
      border-color: #000;
      outline: none;
    }

    &.MuiFormControl-root {
      border: 1px solid #E5E5E5;
      padding: 0 12px;
      display: flex;
      justify-content: center;
    }

    .MuiInputBase-root {
      width: 100%;
      font-size: 14px;
      color: $primary-fg-color;
    }

    .MuiInputBase-root:before,
    .MuiInputBase-root:after {
      content: none;
    }

    .MuiSelect-select:focus {
      background-color: transparent;
    }

    .MuiInputBase-input {
      padding: 0 24px 0 0;
      margin: auto
    }
  }

  &--rich-text {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: $secondary-content-bg;

    .ql-toolbar,
    .ql-container {
      border: 1px solid #E5E5E5;
    }

    .ql-toolbar {
      border-radius: 4px 4px 0 0;
    }
    .ql-container {
      border-radius: 0 0 4px 4px;
    }
    .ql-editor {
      min-height: 150px;
    }
  }

  &--icon {
    position: absolute;
    bottom: 16px;
    transform: translateY(50%);
    right: 8px;
    z-index: 1;

    &.errorText {
      bottom: 36px;
    }
  }

  &--error {
    color: #FF0000;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 5px;
  }
}
