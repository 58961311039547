.breadcrumbs{
  display: flex;
  align-items: center;

  .breadcrumbs--link {
    font-size: 14px;
    text-decoration: none;

    &__selected {
      color: $secondary-fg-color
    }
  }
}
