.multiple-images-uploader {
  box-sizing: border-box;
  height: auto;
  padding: 20px;
  border: 1px dashed rgba(0, 0, 0, 0.1);


  .ant-upload-picture-card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &--top-label,
  &--bottom-label {
    color: #717171;
    font-family: "IBMPlexSansCondensed-Regular", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .ant-upload,
  .ant-upload-list-picture-card-container,
  .ant-upload-list-item {
    width: 144px !important;
    height: 144px !important;
  }
}
